import React, {useEffect, useContext, useState, useCallback} from 'react'
import { Dispatch, log, postServer, syncURL } from '../hooks/hooks';
import { useSpring, animated, interpolate } from 'react-spring'
import { useLocation } from "react-router-dom";
//import QRCode from 'qrcode.react';
import { Button, Dropdown, Label, Icon, Grid, Popup } from 'semantic-ui-react';
import _ from 'lodash';
import ScrollView from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify';
import { toast, Slide, Flip } from 'react-toastify';

import { 
    parseISO, 
    format, 
  } from 'date-fns';
import {pt} from 'date-fns/locale';

const formatCurrency = new Intl.NumberFormat(
    'pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  ).format;

export const Rodape = () => {

    const [heightHalf, setHeightHalf] = useState(window.innerHeight-170)
    const mobile = window.innerWidth<500?true:false
    const reducer = useContext(Dispatch)
    const [{token, userdata, db}, dispatch] = reducer;

    const [animAgenda, setAnimAgenda] = useSpring(() => ({position: 'absolute', bottom: -heightHalf, left:0}));
    const [open, setOpen] = useState(null)

    const [openAgenda, setOpenAgenda] = useState(null)

    const location = useLocation()

    const [total, setTotal] = useState(0)

    const [horario, setHorario] = useState(null)

    const [horarioDiario, setHorarioDiario] = useState(null)

    const [openPopup, setOpenPopup] = useState(false)

    const [horasAtuais, setHorasAtuais] = useState([])

    const [config, setConfig] = useState(null)

    const [feito, setFeito] = useState(false)

    const [pedido, setPedido] = useState(null)

    useEffect(() => {
        if(db){

            db.pedidos.sync({
                remote: syncURL,
                options: {
                    live: true,
                    retry: true,
                },
                query: db.pedidos.find().where('col').eq('pedidos')
            })
              
          
              const configSync = db.config.sync({
                remote: syncURL ,
                direction: {                          // direction (optional) to specify sync-directions
                  pull: true, // default=true
                  push: false  // default=true
                },
                options: {
                    live: false,
                    retry: true,
                },
                query: db.config.find().where('col').eq('config')
              });

              configSync.complete$.subscribe(completed => getConfig(configSync));

           // pegaPedidosFeitos()
            

          /*db.db['config'].$.subscribe(changeEvent => {
            getConfig() 
            console.log('configed')
          });*/

          db['carrinho'].$.subscribe(changeEvent => {
            getAllPedido()
          });

          getAllPedido();
        }
    },[db])

    /*useEffect(() => {

        let subtotal = 0;
        _.forOwn(pedido, (value, key) => {
            console.log(value.id)
            subtotal+=Number(value.valor)
          })
          setTotal(formatCurrency(subtotal))
    },[pedido])*/

    useEffect(() => {
        if(!_.isNil(openAgenda)){
            if(openAgenda){
                setAnimAgenda({from: {position: 'absolute', bottom: -heightHalf}, to:{position: 'absolute', bottom: 0, left:0}})
            }else{
                setAnimAgenda({from: {position: 'absolute', bottom: 0, left:0}, to:{position: 'absolute', bottom: -heightHalf}})
            }
        }
    },[openAgenda])

    useEffect(() => {
        log() && console.log(token, '3', location.pathname )
    },[token])

    useEffect(() => {
        if(config){
            console.log('config ok')
            getData()
        }
    },[config])

    async function getAllPedido(){
        const carrinho = await db.carrinho
        .find()
        .exec();

        let subtotal = 0;
        _.forOwn(carrinho, (value, key) => {
            //console.log(value.id)
            subtotal+=Number(value.valor)
          })

          setPedido(carrinho)
          //let alltotal = convertToUSD(subtotal)
          setTotal(formatCurrency(subtotal))
    }

    async function getConfig(){
        console.log('getconfig')
        const myconfig = await db.config
        .find()
        .where('estabelecimento')
        .eq('tonhoi')
        .exec();

        if(myconfig.length>0){
            console.log('have config')
            setConfig(myconfig)
        }else{
            console.log('no config')
        }
    }

    async function getData(){
       // console.log('getdata', config)
        const formated = format(getTodayDate(), 'iiii', {timeZone: 'America/Sao_Paulo', locale:pt});
        //const horaatual = format(getTodayDate(), 'HH:mm', {timeZone: 'America/Sao_Paulo', locale:pt});
        const horaatual = '11:00'
        //await db.config.where('estabelecimento').elemMatch({ author: 'autobot', votes: {$gte: 5}})

        let hrs =  _.find(config[0].funcionamento, ['diasemana', formated])

        if(!hrs){
            setHours(null)
            return
        }
        console.log(hrs.horarios, formated)

        //let horaatual = '13:00'

        for(var i=0; i < hrs.horarios.length; i++){
           //_.forOwn(hrs.horarios[i], (value, key) => {
            //console.log(isBefore(parseISO('13:00'), parseISO(value)), value)
            if(Number(hrs.horarios[i].das.split(':')[0]) <= Number(horaatual.split(':')[0]) && Number(hrs.horarios[i].ate.split(':')[0]) > Number(horaatual.split(':')[0])){
                //console.log('é', i)
                setHours(Number(horaatual.split(':')[0]),Number(hrs.horarios[i].ate.split(':')[0]), hrs.emtempo)
            }
          // })
        }
       
      }

    async function pegaPedidosFeitos(allhrs){
        const formated = format(getTodayDate(), 'yyyy-MM-dd', {timeZone: 'America/Sao_Paulo',});

        const resp = await db.pedidos
        .find()
        .where('dia')
        .eq(formated)
        .exec()
  
        const result = _.pick(_.countBy(resp, 'horario'),  allhrs);
       /* _.forOwn(result, (value,key) => {
            console.log('-> ' + key, value)
        })*/

        return result

        //console.log(result['14:00'])

    }

    async function setHours(ini, fin, intervalo){

        //const horaatual = format(getTodayDate(), 'HH:mm:ss', {timeZone: 'America/Sao_Paulo', locale:pt});
        const horaatual = '11:00:00'

        let parcela = intervalo
        let min = 60 / parcela
        let horas = []
        let count = 0
        let inicial = ini
        let final = fin

        let allhrs = []

        for(var i = inicial; i<final; i++){
            for(let a = 0; a<min; a++){
                let mountH = i<10?('0'+i):i
                let mountS = (parcela*a)<10?('0'+a):parcela*a;
                if(compareTime(horaatual, mountH+':'+mountS+':00')){
                    allhrs.push(mountH+':'+mountS)
                    horas.push({ key: count, text: mountH+':'+mountS, value: mountH+':'+mountS})
                }
                count++
            }
        }

        setHorasAtuais(allhrs)

        let jafeitos = await pegaPedidosFeitos(allhrs)

        let newHrs = []

        for(let a = 0; a<horas.length; a++){
            if(jafeitos[horas[a].text] < 3 || !jafeitos[horas[a].text]){
                newHrs.push(horas[a])
            }
        }
        setHorarioDiario(newHrs)
    }

    function compareTime(startTime, endTime){
        var regExp = /(\d{1,2})\:(\d{1,2})\:(\d{1,2})/;
        if(parseInt(endTime.replace(regExp, "$1$2$3")) >= parseInt(startTime.replace(regExp, "$1$2$3"))){
            return true
        }else{
            return false
        }
    }

    const openMyAgenda = () => {
        setOpenAgenda(!openAgenda)
    } 

    function convertToUSD(valor){
        let tiraponto = _.replace(valor.replace(/[^\d.,-]/g, ''),".","")
        let toUSD = _.replace(tiraponto, ",", ".")
        return toUSD
      }

    function handleChange(e, { name, value }){
        console.log(value)
        setHorario(value)
    }

    function getTodayDate(){
        const parsedDate1 = parseISO(new Date().toISOString());
        return parsedDate1
    }

    async function enviaPedido() {
        if(!horario){
            setOpenPopup(true)
            return
        }
        if(_.size(pedido) === 0){
            notify('Você não possui itens no carrinho!', 'warning', 2500);
            return
        }

        //const znDate1 = zonedTimeToUtc(parsedDate1, 'America/Sao_Paulo');
        const dia = format(getTodayDate(), 'yyyy-MM-dd', {timeZone: 'America/Sao_Paulo',});

        const qnt = await db.pedidos
        .find()
        .exec();

        let jafeitos = await pegaPedidosFeitos(horasAtuais)
        console.log(jafeitos[horario], horario, qnt)

        if(jafeitos[horario] < 3 || !jafeitos[horario]){
            setFeito(true)
            toast('Pedido efetuado! Aguarde a confirmação pelo WhatsApp.', {type: toast.TYPE.SUCCESS, transition: Flip})
             salvaPedidoDB(qnt.length, dia)
        }else{
            getData()
            notify('Esse horário não está mais disponível', 'warning', 2500);
            
        }
    }

    async function salvaPedidoDB(qnt, dia){
       
        //const formated = format(getTodayDate(), 'yyyy-MM-dd HH:mm', {timeZone: 'America/Sao_Paulo',});
        let itens = [];

        _.forOwn(pedido, (value, key) => {
            itens.push({codigo:value.id, nome:value.nome, quantidade:value.quantidade, obs:value.obs})
          })

          
        await db.pedidos.insert({
            nome: userdata.nome,
            telefone: userdata.celular,
            numero: qnt.toString(),
            dia:dia,
            horario: horario,
            pratos: itens,
            valor: total
        });

        console.log(itens, qnt )

        enviaWhatsapp(qnt)
    }

    async function enviaWhatsapp(qnt){
        let itens = '🍜  *Pedido feito pelo WebApp*';
        itens += '%0a';
        itens += '%0a Pedido: ' + qnt + ' de ' + userdata.nome;
        itens += '%0a Celular: '+ userdata.celular;
        itens += '%0a';
        
        _.forOwn(pedido, (value, key) => {
            itens+='%0a• '+ value.quantidade + 'x - _'+ value.nome + '_ ' + '' + (value.obs?value.obs:'')
          })

          let pedidoTotal = itens + '%0a'+ '%0aHorário de retirada: '+ horario + '%0a' +  '%0aTOTAL: ' + total

        //window.open("https://api.whatsapp.com/send?phone=+5511966660383&text="+pedidoTotal , "_blank")

        var randomNumber = _.times(1, () => _.times(4, () => _.random(35).toString(36)).join('')).join('-');
        let valNumber = randomNumber.toLowerCase()

        const resposta = await postServer('55'+userdata.celular, userdata.celular+'_'+valNumber, pedidoTotal )

        removeCarrinho()
        openMyAgenda()
        setHorario(null)
        setFeito(false)
    }

    async function closePopupGetPedidos(){
        //const formated = format(getTodayDate(), 'yyyy-MM-dd', {timeZone: 'America/Sao_Paulo',});
        if(horasAtuais.length === 0){
            toast('Não tem horario de retirada ou o restaurante esta fechado.', {type: toast.TYPE.ERROR, transition: Flip})
        }
        if(openPopup){
            setOpenPopup(false)
        }
        

    } 

    async function removeItemCarrinho(item){
        //db.db['carrinho'].find().where('id').eq(item).remove()

        const getItem = await db['carrinho'].find().where('id').eq(item).exec()

        console.log(getItem[0].quantidade, item)

        if(getItem[0].quantidade > 1){

           // console.log('>1')
            const qnt =  Number(getItem[0].valor) / getItem[0].quantidade
            const calc = Number(getItem[0].valor) - qnt


            await getItem[0].update({
                $set: {
                    quantidade: getItem[0].quantidade - 1,
                    valor:calc.toString()
                }
            });

        }else{
           // console.log('only 1')
            db['carrinho'].find().where('id').eq(item).remove()
        }

    }

    async function removeCarrinho(){
        const carrinho = await db.carrinho
        .find()
        .exec();

        _.forOwn(carrinho, (value, key) => {
            db['carrinho'].find().where('id').eq(value.id).remove()
        })
    }

    function getOrder () {
        let itens = [];
        
        _.forOwn(pedido, (value, key) => {
            //console.log(value.quantidade, value.nome, value.valor, value.obs, key, value.id)
            itens.push([value.quantidade, value.nome, value.valor, value.obs, key, value.id])
          })

        
        //setTotal(subtotal)
        return itens.map( (item, index) => {
            
            return <Grid.Row key={index} style={{paddingTop:14, paddingBottom:1, marginTop:0}}>
                        <Grid.Column style={{width:'16%', marginTop:0, paddingRight:0}}>
                            <Grid.Row style={{display:'flex', alignItems:'center', justifyContent:'space-around'}}>
                                <Icon onClick={() => removeItemCarrinho(item[5])} fitted style={{fontSize:19}} name='trash'/>
                                <Label>{item[0]}</Label>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column style={{width:'42%', display:'flex', flexDirection:'column', justifyContent:'flex-start',alignContent:'center', marginTop:0}}>
                            <Grid.Row>{item[1]}</Grid.Row>
                             {item[3] && <Grid.Row style={{color:'#9E9E9E'}}>{'• '+item[3]}</Grid.Row> }
                        </Grid.Column>     
                        <Grid.Column style={{width:'16%', marginTop:0}}>
                            {formatCurrency(item[2])}
                        </Grid.Column> 
                    </Grid.Row>
        })
    }
 
    return (
        <div>
            {location.pathname !== '/' && <div className='StickyBox' style={{ width:'100%', backgroundColor:'transparent', height:60 }} >
                {location.pathname !== '/login' &&
                    <animated.div  style={{display:'flex', position: 'absolute', right:15, bottom: 10, zIndex:10}}>
                       
                        <Label size={'massive'} circular color={_.size(pedido) != 0 ? 'green' : 'black'} key={'green'} style={{cursor:'pointer'}} onClick={openMyAgenda}>
                            <Icon name='food' style={{width:5, height:5}}/>
                            <Label color={_.size(pedido) != 0 ? 'green' : 'black'} floating>
                                {_.size(pedido)}
                            </Label>
                        </Label> 
                    </animated.div>
                }
               
                <div>
                   
                        <animated.div style={animAgenda}>
                            <div style={{width:window.innerWidth, zIndex:1}}>{ (
                                <div style={{left:!mobile&&'50%', transform:!mobile&&'translateX(-50%)', position: 'absolute', bottom: 0, height:heightHalf, maxWidth:500, backgroundColor:'#ffffff', boxShadow: "0px 20px 50px 10px #9E9E9E", shadowOpacity: 0, borderRadius: 10,shadowColor: '#000', shadowRadius: 0}}>
                                    <div style={{margin:21, height:heightHalf-200}}>
                                    <div style={{display:'flex', flexDirection:'row', marginBottom:10, justifyContent:'space-between'}}>
                                    <h3>Resumo do pedido</h3>
                                    <div style={{cursor:'pointer'}} onClick={openMyAgenda}><Icon size='large' name='close'/></div>
                                    </div>
                                    <ScrollView width='100%' height='100%' >
                                       
                                        <Grid divided='vertically'> 
                                            {pedido && getOrder()}
                                        </Grid>
                                        
                                    </ScrollView>
                                    <Grid divided='vertically'> 
                                        <Grid.Row style={{paddingTop:14, paddingBottom:1, marginTop:0}}>
                                            <Grid.Column style={{width:'64%', marginTop:0, fontSize:18}}>
                                                {'TOTAL'}
                                            </Grid.Column>
                                            
                                            <Grid.Column style={{width:'30%', marginTop:0, fontSize:18}}>
                                                {total}
                                            </Grid.Column> 
                                        </Grid.Row>
                                        <Grid.Row style={{paddingLeft:10, paddingTop:14, paddingBottom:1, marginTop:0}}>
                                        <span style={{fontSize:15}}>Gostaria de retirar o pedido às &nbsp; {' '}</span>
                                        <Popup
                                        content='Escolha aqui o horário que deseja buscar'
                                        on='click'
                                        open={openPopup}
                                        offset={'-50px, 5px'}
                                        position='top right'
                                        trigger={
                                        <Dropdown
                                            style={{fontSize:15}}   
                                            options={horarioDiario}
                                            placeholder='escolha o horário'
                                            onChange={handleChange}
                                            onClick={closePopupGetPedidos}
                                        />
                                        }
                                        />
                                        </Grid.Row>
                                        <Grid.Row style={{paddingTop:14, paddingBottom:1, marginTop:0}}>
                                        <Button onClick={enviaPedido} disabled={feito || !horario} positive style={{height:35, marginBottom:12, marginRight:12, marginLeft:5}} type="success">Enviar pedido ao restaurante</Button>
                                        </Grid.Row>
                                    </Grid>
                                    </div>
                                </div>)}
                            </div>
                        </animated.div>
                </div>
            </div>}
        </div>
        )
}