import React, { useState, useEffect, forwardRef, memo } from 'react'
import DataGrid, { Column, RowDragging, Scrolling, Lookup, Sorting } from 'devextreme-react/data-grid';
import _ from 'lodash';
import { Button, Divider, Header } from 'semantic-ui-react';

const OrdenarLista = forwardRef((props, ref) => {

    const [state, setState] = useState({data:[], full:[], aberto:false});
    const {data, full, aberto} = state

    useEffect(() => {
        if(props.data){
            let uniq = _.uniqBy(JSON.parse(JSON.stringify(props.data)), 'categoria')
            let data = _.orderBy(uniq, 'order', 'asc');
            setState(prevState => ({ ...prevState, data, full:props.data}))
        }
        return () => {}
    },[props])

    const onReorder = (e) => {
        const visibleRows = e.component.getVisibleRows();
        const newData = [...data];
        const toIndex = newData.indexOf(visibleRows[e.toIndex].data);
        const fromIndex = newData.indexOf(e.itemData);

        newData.splice(fromIndex, 1);
        newData.splice(toIndex, 0, e.itemData);

        setState(prevState => ({ ...prevState, data:newData}))
    }

    const saveReorder = () => {

        for(let i=0; i<data.length; i++){
            for(let a=0; a<full.length; a++){
                if(i+1 != full[a].order && data[i].categoria === full[a].categoria){
                    full[a].atomicPatch({order:i+1});
                }
            }
        }
    }

    return (
        <React.Fragment>
            <Divider horizontal>
                <Header as='h4'>
                    Reordenar categorias
                </Header>
            </Divider>
            {aberto && <div style={{marginBottom:20}}>
                <DataGrid
                    height={440}
                    dataSource={data}
                    showBorders={true}
                >
                    <RowDragging
                    allowReordering={true}
                    onReorder={onReorder}
                    showDragIcons={false}
                    />
                    <Scrolling mode="virtual" />
                    <Sorting mode="none" />
                    <Column dataField="order" width={55} />
                    
                    <Column dataField="categoria" />
                </DataGrid>
            
            </div>
}
                <div style={{marginTop:5, display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <Button onClick={() => setState(prevState => ({ ...prevState, aberto:!aberto}))} primary>{!aberto?'Reordenar as categorias':'Fechar'}</Button>
                    {aberto && <Button onClick={saveReorder} secondary>Salvar</Button>}
                </div>
        </React.Fragment>
      );
})

export default memo(OrdenarLista)