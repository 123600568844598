import {
    createRxDatabase,
    addRxPlugin
} from 'rxdb/plugins/core';

import _ from 'lodash';

import { RxDBEncryptionPlugin } from 'rxdb/plugins/encryption';
import { RxDBReplicationPlugin } from 'rxdb/plugins/replication';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { removeRxDatabase } from 'rxdb';

import {usersSchema, itensSchema, imagesSchema, pedidosSchema, configSchema, carrinhoSchema, verSchema} from '../schema/schema';

import { 
  parseISO, 
  formatISO,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';


addRxPlugin(RxDBValidatePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBReplicationPlugin);
addRxPlugin(RxDBEncryptionPlugin);
addRxPlugin(RxDBUpdatePlugin);


//import * as RxDB from 'rxdb';

addRxPlugin(require('pouchdb-adapter-idb'));
addRxPlugin(require('pouchdb-adapter-http')); //enable syncing over http




let dbPromise = null;

const _create = async (dbName) => {
    const db = await createRxDatabase({
        name: dbName,
        adapter: 'idb', 
        password: 'antidoto1234',
        multiInstance: false,
    });

    window['db'] = db;
  
    //
    //console.log('init')

    let update = false

    await db.addCollections({
      // key = collectionName
      ver: {
        schema: verSchema,
      },
      config: {
        schema: configSchema,
      },
      users: {
        schema: usersSchema,
      },
      itens: {
        schema: itensSchema,
      },
      carrinho: {
        schema: carrinhoSchema,
      },
      pedidos: {
        schema: pedidosSchema,
      },
    })

    db.itens.preInsert(function(plainData){
      //console.log('preInsert')
      var randomNumber = _.times(4, () => _.times(8, () => _.random(35).toString(36)).join('')).join('-');
      
      plainData.id = randomNumber;
      
    }, false);
  
    db.config.preInsert(function(plainData){
      //console.log('preInsert')
      var randomNumber = _.times(4, () => _.times(8, () => _.random(35).toString(36)).join('')).join('-');
      
      plainData.id = randomNumber;
      
    }, false);

    db.pedidos.preInsert(function(plainData){
      //console.log('preInsert')
      var randomNumber = _.times(4, () => _.times(8, () => _.random(35).toString(36)).join('')).join('-');
      
      const parsedDate = parseISO(new Date().toISOString());
      const znDate = zonedTimeToUtc(parsedDate, 'America/Sao_Paulo');

      plainData.id = randomNumber;
      plainData.diacompleto = formatISO(znDate);
  }, false);

    /*await db.collection({
      name: 'ver',
      schema:verSchema,
    }).then(vercol => {
      
      vercol.find().exec().then(doc => {
        if(doc.length === 0){
          console.log('insert ver')
          vercol.insert({
            id: Math.round(Math.random(600000)).toString(),
          });
        }
      });

    }).catch((error) => {
      console.log('error', error.parameters)
      if(error.parameters.previousSchemaHash !== error.parameters.schemaHash){
        console.log('deletando e atualizando')
        update = true
      }
    });

    if(update){
      await db.remove();
      return null
    }

    await db.collection({
      name: 'config',
      schema:configSchema,
    })
  
    await db.collection({
        name: 'users',
        schema:usersSchema,
    });
  
    //console.log('users')
  
    await db.collection({
      name: 'itens',
      schema:itensSchema,
    });
  
    //console.log('itens')
  
    await db.collection({
      name: 'images',
      schema:imagesSchema,
    });

    //console.log('images')

    await db.collection({
      name: 'pedidos',
      schema:pedidosSchema,
    });

    //console.log('pedidos')

    await db.collection({
      name: 'carrinho',
      schema:carrinhoSchema,
    });
*/
    //console.log('carrinho')
    
    
    

    //console.log(formated)

    

  
    //console.log('pedidos')

   
  
    // ================= SYNC 
  
    /*db.itens.sync({
        remote: syncURL + dbName + '/itens',
        direction: {                          // direction (optional) to specify sync-directions
          pull: true, // default=true
          push: false  // default=true
        },
        options: {
            live: true,
            retry: true,
        },
    })*/

    
  /*
    const imagesSync = db.images.sync({
      remote: syncURL + dbName + '/images',
      options: {
          live: true,
          retry: true,
      },
    });
  /*
    //imagesSync.active$.subscribe(active => console.log('imagesSync?', active));
  
    // ================= SYNC 
    
    //db.evento.find().exec().then(doc => console.log('resposta: ', doc));
    //db.evento.findOne('42A8A87A-PLKT-9999-7777-0CJ15A709625').exec().then(doc => console.log('resposta: ', doc));
  
    /*const doc = await userCollection.insert({
      nome: 'Phillipe',
      sobrenome: 'Murray',
      email:'phi.coimbra@gmail.com'
    });*/
   db.config.find().exec().then(doc => console.log('resposta: ', doc));
  
   
   //userCollection.findOne('phi.coimbra@gmail.com').exec().then(doc => console.log('resposta: ', doc));
  
      //db['pedidos'].find().exec().then(datadoc => console.log(datadoc.length, datadoc[0].dia))
  
    //console.log('find');
    //console.log('add: ', doc)
  
  
    //const doc = await db.images.insert({nome:'antidoto', id:'42A8A87A-F71C-1111-B81D-0CJ15A709625'});
  
    //await db.remove();

    return db;
};

export const get = async (dbName) => {
    if (!dbPromise)
    dbPromise = await _create(dbName);
      console.log('nulou? ', dbPromise?'VERSAO OK':'VERSAO ERRADA' )
        if(!dbPromise){
          console.log('cria ourta')
          dbPromise = _create(dbName);
        }
    return dbPromise;
}
