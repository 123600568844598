export const itensSchema = {
version: 0,
    title: 'itens schema',
    description: 'info dos itens do restaurante',
    type: 'object',
    properties: {
        id: {
            type: 'string',
            
        },
        nome: {
            type: 'string',
        },
        produtoid: {
            type: 'string',
            primary: true,
        },
        quantidade: {
            type: 'number',
        },
        descricao: {
            type: 'string',
        },
        order:{
            type: 'number',
        },
        categoria: {
            type: 'string',
        },
        ingredientes: {
            type: 'string',
        },
        diasdasemana: {
            type: 'array',
			items: {
				type: 'string'
			}
        },
        foto: {
            type: 'string',
        },
        galeriadefotos: {
            type: 'array',
			items: {
				type: 'string'
			}
        },
        preco: {
            type: 'string',
        },
        precoanterior: {
            type: 'string',
        },
        disponibilidade: {
            type: 'boolean',
        },
        destaque:{
            type: 'boolean',
        },
        col:{
            type: 'string',
            default:'itens'
        },
    }
}

export const imagesSchema = {
    version: 0,
    title: 'imagem schema',
    description: 'imagens do app',
    type: 'object',
    properties: {
        url: {
            type: 'string',
        },
        id: {
            type: 'string',
            primary: true,
        },
        col:{
            type: 'string',
            default:'images'
        },
    },
}

export const pedidosSchema = {
    version: 0,
    title: 'pedidos schema',
    description: 'pedidos totais',
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
        },
        col:{
            type: 'string',
            default:'pedidos'
        },
        nome: {
            type: 'string',
        },
        telefone: {
            type: 'string',
        },
        numero: {
            type: 'string',
        },
        dia: {
            type: 'string',
        },
        diacompleto:{
            type: 'string',
        },
        horario: {
            type: 'string',
        },
        pratos: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    codigo: {
                        type: 'string'
                    },
                    nome: {
                        type: 'string'
                    },
                    quantidade: {
                        type: 'integer'
                    },
                    obs:{
                        type: 'string',
                        default:''
                    }
                }
            }       
        },
        valor: {
            type: 'string',
        },
        status: {
            type: 'number',
            default:0
        },
        pago:{
            type: 'boolean',
            default:true
        },
        indexes: [
          'dia',
        ]
    },
   /* options: {
        // This will activate timestamps for this model
        timestamps: true
      }*/
}

export const usersSchema = {
    version: 0,
    title: 'user schema',
    description: 'usuarios e cadastro de informações',
    type: 'object',
    properties: {
        id: {
            type: 'string',
            
        },
        nome: {
            type: 'string',
        },
        email: {
            type: 'string',
        },
        celular: {
            type: 'string',
            primary: true,
        },
        col:{
            type: 'string',
            default:'users'
        }
    },
};

export const configSchema = {
    version: 0,
    title: 'config schema',
    description: 'configuracao do app',
    type: 'object',
    properties: {
        id: {
            type: 'string',
        },
        col:{
            type: 'string',
            default:'config'
        },
        funcionamento: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    diasemana: {
                        type: 'string'
                    },
                    horarios: {
                        type: 'array',
                        items: {
                            type: 'object',
                            properties: {
                                das: {
                                    type: 'string'
                                },
                                ate: {
                                    type: 'string'
                                },
                                
                            }
                        }       
                    },
                    pratos: {
                        type: 'integer',
                       
                    },
                    emtempo:{
                        type: 'integer',
                       
                    },
                    limite:{
                        type: 'integer',
                   
                    }
                }
            }       
        },
        estabelecimento: {
            type: 'string',
            primary: true,
        }
    }
};

export const carrinhoSchema = {
    version: 0,
    title: 'carrinho schema',
    description: 'carrinho do pedido',
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
        },
        nome: {
            type: 'string'
        },
        valor: {
            type: 'string'
        },
        quantidade: {
            type: 'number'
        },
        porcao: {
            type: 'number'
        },
        obs: {
            type: 'string'
        },
    }
};

export const verSchema = {
    version: 0,
    title: 'versionamento schema',
    description: 'deleta e atualiza os schemas',
    type: 'object',
    properties: {
        id: {
            type: 'string',
            primary: true,
        },
        ver: {
            type: 'number',
            default:5
        }
    }
};

