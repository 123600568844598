import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import Tabs from "devextreme-react/tabs";
import _ from "lodash";

import { Dispatch } from "./../hooks/hooks";
import HorizontalScroll from "./HorizontalScroll";
import { Button } from "devextreme-react/button";

export function changeLanguage(language, categoria) {
  if (language !== "pt") {
    switch (categoria) {
      case "ARROZ":
        return "RICE";
      case "DESTAQUES":
        return "HIGHLIGHTS";
      case "ENTRADAS FRIAS":
        return "COLD STARTERS";
      case "CAMARÕES":
        return "SHRIMP";
      case "LULAS":
        return "SQUID";
      case "ESPECIALIDADES DA CASA":
        return "CHEF`S SPECIALTIES";
      case "PEIXES – FILÉS":
        return "FISH";
      case "FRANGO":
        return "CHICKEN";
      case "CARNE SUÍNA":
        return "PORK";
      case "CARNE BOVINA":
        return "BEEF";
      case "OSTRAS":
        return "OYSTERS";
      case "OMELETES":
        return "OMELET";
      case "MASSAS":
        return "DUMPLING";
      case "MACARRÃO DE ARROZ (BI-FUM)":
        return "MEI FUN (VERMICELLI)";
      case "MACARRÃO ESPECIAL DE OVOS":
        return "EGG`S NOODLE SPECIAL";
      case "MACARRÃO DE TRIGO":
        return "WHEATS`S NOODLE";
      case "PATO":
        return "DUCK";
      case "SOPAS":
        return "SOUP";
      case "SOBREMESAS (Porções Individuais)":
        return "DESSERT (Singles)";
      case "SOBREMESAS QUENTES (Porções)":
        return "DESSERT";
      case "GARRAFA 600ml":
        return "BOTTLE 600ml";
      case "LICOR":
        return "LICUOR";
      case "SUCOS":
        return "JUICE";
      case "OUTROS":
        return "OTHERS";
      case "REFRIGERANTES":
        return "SODA";
      default:
        return categoria;
    }
  } else {
    return categoria;
  }
}

const MenuTab = (props) => {
  const { chooseMenu, itens } = props;
  const [tabs, setTabs] = useState([]);
  const reducer = useContext(Dispatch);
  const [{ language }] = reducer;

  useEffect(() => {
    //getUniqueTabs()

    if (itens) {
      getUniqueTabs();
    }
  }, [itens]);

  function renderTabItem(item) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className={"myClip2"} style={{ fontSize: 13 }}>
          <b>{item.categoria}</b>
        </div>
      </div>
    );
  }

  function getUniqueTabs() {
    let allItems = _.uniqBy(itens, "categoria");
    let tabs = [];
    for (let i = 0; i < allItems.length; i++) {
      tabs.push({
        categoria: changeLanguage(language, allItems[i].categoria),
        id: i,
      });
    }
    setTabs(tabs);
  }

  const items = tabs.map((tab, i) => (
    <Button
      text="Contained"
      type="normal"
      stylingMode="contained"
      onClick={() => chooseMenu.current.chooseMenu({ itemData: { id: i } })}
      key={i}
      height={30}
    >
      {tab.categoria}
    </Button>
  ));

  return (
    <div>
      <HorizontalScroll className={`horizontal-scroll`}>
        {items}
      </HorizontalScroll>
      {/*
        <Tabs
          dataSource={tabs}
          onItemClick={(e) => chooseMenu.current.chooseMenu(e)}
          rtlEnabled={false}
          scrollByContent={true}
          showNavButtons={true}
          itemRender={renderTabItem}
          useNativeScrolling={false}
        />
      */}
    </div>
  );
};

export default MenuTab;
