import React, {useState, useImperativeHandle, forwardRef, useEffect} from 'react'
import SelectBox from 'devextreme-react/select-box';
import _ from 'lodash';

import { toast, Slide, Flip } from 'react-toastify';

import { Checkbox, Divider, Modal, Button, Input, TextArea, Form, Header } from 'semantic-ui-react';

const semanaLimpa = [{id:0, dia:'todo o cardápio', check:false}, {id:1, dia:'segunda-feira', check:false}, {id:2, dia:'terça-feira', check:false}, {id:3, dia:'quarta-feira', check:false}, {id:4, dia:'quinta-feira', check:false}, {id:5, dia:'sexta-feira', check:false}, {id:6, dia:'sábado', check:false},{id:7, dia:'domingo', check:false}]

const PopAddPrato = forwardRef((props, ref) => {

    const {db, changeList, getItems, itens, syncDB} = props

    //const semanatoda = [{id:0, dia:'todo o cardápio', check:false}, {id:1, dia:'segunda-feira', check:false}, {id:2, dia:'terça-feira', check:false}, {id:3, dia:'quarta-feira', check:false}, {id:4, dia:'quinta-feira', check:false}, {id:5, dia:'sexta-feira', check:false}, {id:6, dia:'sábado', check:false},{id:7, dia:'domingo', check:false}]
    const [diasemana, setDiasemana] = useState(semanaLimpa)
    const [edit, setEdit] = useState({option:false, item:[], disponibilidade:false, nome:'', descricao:'', preco:'', precoanterior:'', codigo:'', openPopupAdd:false, categorias:[], categoriaEscolhida:1})

    const {option, item, disponibilidade, nome, descricao, preco, codigo, openPopupAdd, categorias, categoriaEscolhida} = edit
    

    useImperativeHandle(
        ref,
        () => ({

            openPopup(item) {

                let cloned = _.clone(diasemana)

                for(let i=0; i<item.diasdasemana.length; i++){

                    for(var a=0; a<diasemana.length; a++){
                        if(item.diasdasemana[i] === diasemana[a].dia){
                            cloned[a].check = true
                        }
                    }
                }

                setDiasemana(cloned)

                setEdit(prevState => ({ ...prevState, 
                    option:true, 
                    item:item, 
                    disponibilidade:item.disponibilidade,
                    nome:item.nome,
                    descricao:item.descricao,
                    preco:item.preco,
                    precoanterior:item.precoanterior
                } ))
            }
        }),
    )

    useEffect(() => {
        if(itens){
            let allCategorias = []
            for(let i=0; i<itens.length; i++){
                allCategorias.push({categoria:itens[i].categoria})
            }
            let sortedCategorias = _.uniqBy(allCategorias, 'categoria')
            let orded = _.orderBy(sortedCategorias, 'categoria', 'asc')
           
            for(let a=0; a<orded.length; a++){
                orded[a].ID = a+1
            }
            setEdit(prevState => ({ ...prevState, 
                categorias:orded
            }))
           // console.log(orded)
        }

        return () => {}
    },[itens])

    async function onItemClick(e) {
        console.log(e.itemData, 'clicked')
        //setDiaescolhido(e.itemData)
        //setDiaescolhido(e.itemData.id)
        setEdit(prevState => ({ ...prevState, 
            categoriaEscolhida:e.itemData.categoria
        }))

      }

    async function salvaDados() {
        let newArray = [];

        if(!nome || !descricao || !preco || !codigo || !categoriaEscolhida ){
            console.log('falta coisa')
            return
        }

        _.forOwn(diasemana, (value, key) => {
            if(value.id !== 0 && value.check === true){
                newArray.push(value.dia)
            }
        })

        //console.log(newArray, item, disponibilidade, nome, descricao, preco, codigo, categoriaEscolhida)
        try {
            const doc = await db.itens.insert({
                produtoid:codigo,
                nome,
                quantidade:-1,
                descricao,
                categoria:categoriaEscolhida,
                ingredientes:'',
                diasdasemana:newArray,
                foto:"chinese.png",
                galeriadefotos:["chinesef.png"],
                preco,
                precoanterior:'',
                disponibilidade
            })

            syncDB()
            getItems()

            setDiasemana(semanaLimpa)
            setEdit(prevState => ({ ...prevState, item:[], disponibilidade:false, nome:'', descricao:'', preco:'', precoanterior:'', codigo:'', openPopupAdd:false, categorias:[], categoriaEscolhida:1} ))
        } catch (error) {
            if(error.parameters.pouchDbError.status === 409){
                toast('Já existe um item no cardápio com esse código, esse código é único!', {type: toast.TYPE.ERROR, transition: Flip})
              }else{
                toast('Erro desconhecido', {type: toast.TYPE.ERROR, transition: Flip})
              }
        } 
          
    }

    function onChange(i) {
        let cloned = _.clone(diasemana)
        _.forOwn(cloned, (value, key) => {
            if(value.dia === i.dia){
                cloned[key].check = !cloned[key].check
            }
        })
        setDiasemana(cloned)
    }

    function clearCheck() {
        let cloned = _.clone(diasemana)
        _.forOwn(cloned, (value, key) => {
            
                cloned[key].check = false
         
        })
        setDiasemana(cloned)
    }

    function setData(e,v){
        //console.log(v)
       
        setEdit(prev => ({ 
         ...prev,
         [v.name]:v.value,
       }));
       
    }

    return (
        <div style={{marginBottom:10}}>
        <Divider horizontal>
            <Header as='h4'>
                Adicionar novo item
            </Header>
        </Divider>
        <Button onClick={e => setEdit(prev => ({ 
         ...prev,
         openPopupAdd:true,
       }))} secondary>Adicionar um novo item</Button>
        <Modal
            size={'mini'}
            open={openPopupAdd}
        >
            <Modal.Header>Adicione um novo item e seus dados</Modal.Header>
            <Modal.Content> 
                <SelectBox
                    placeholder="Escolha uma categoria..."
                    dropDownOptions={{ width: 280, height:300 }}
                    valueExpr="ID"
                    displayExpr="categoria"
                    dataSource={categorias}
                    selectedItemKey={categoriaEscolhida}
                    onItemClick={onItemClick}
                />
                <Input name='codigo' fluid focus placeholder='codigo' value={codigo} onChange={setData}/>
                <Input name='nome' fluid focus placeholder='nome' value={nome} onChange={setData}/>
                <Form><TextArea rows={3} name='descricao' placeholder='descricao' value={descricao} onChange={setData}/></Form>
                <Input name='preco' fluid placeholder='preco' value={preco} onChange={setData}/>
                <Divider />
                <div style={{display:'flex', flexDirection:'column'}}>
                {diasemana.filter(item => item.id > 0).map((i, a) => {
                    //console.log(item, i.dia, i.check)
                    return <div key={a} style={{margin:3}}><Checkbox label={_.capitalize(i.dia)} onChange={() => onChange(i)} checked={i.check} /></div>
                })}
                <Divider />
                <div style={{marginTop:3}}><Checkbox label='Disponibilidade' onChange={() => setEdit(prevState => ({ ...prevState, disponibilidade:!disponibilidade} ))} checked={disponibilidade} /></div>
                </div>    
            </Modal.Content>
            <Modal.Actions>
            <Button onClick={() => setEdit(prev => ({ 
                ...prev,
                openPopupAdd:false,
            }))}>
                Fechar
            </Button>
            <Button positive onClick={salvaDados}>
                Salvar
            </Button>
            </Modal.Actions>
        </Modal>
        </div>
    )
})

export default PopAddPrato