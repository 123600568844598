import React, {useState, useImperativeHandle, forwardRef} from 'react'
import DropDownButton from 'devextreme-react/drop-down-button';
import _ from 'lodash';

import { Checkbox, Divider, Modal, Button, Header, Input, TextArea, Form } from 'semantic-ui-react';

const Popdisponibilidade = forwardRef((props, ref) => {

    const {db, changeList, getItems, syncDB} = props

    const semanatoda = [{id:0, dia:'todo o cardápio', check:false}, {id:1, dia:'segunda-feira', check:false}, {id:2, dia:'terça-feira', check:false}, {id:3, dia:'quarta-feira', check:false}, {id:4, dia:'quinta-feira', check:false}, {id:5, dia:'sexta-feira', check:false}, {id:6, dia:'sábado', check:false},{id:7, dia:'domingo', check:false}]
    const [diasemana, setDiasemana] = useState([{id:0, dia:'todo o cardápio', check:false}, {id:1, dia:'segunda-feira', check:false}, {id:2, dia:'terça-feira', check:false}, {id:3, dia:'quarta-feira', check:false}, {id:4, dia:'quinta-feira', check:false}, {id:5, dia:'sexta-feira', check:false}, {id:6, dia:'sábado', check:false},{id:7, dia:'domingo', check:false}])
    const [edit, setEdit] = useState({option:false, item:[], disponibilidade:false, destaque:false, nome:'', descricao:'', preco:'', precoanterior:''})

    const [diaescolhido, setDiaescolhido] = useState(0);

    const {option, item, disponibilidade, nome, descricao, preco, precoanterior, destaque} = edit
    

    useImperativeHandle(
        ref,
        () => ({
            /*mostraPopup(v) {
                setPopup(v)
            },*/
            openPopup(item) {
                //console.log(item.diasdasemana.length)
                

                let cloned = _.clone(diasemana)

                for(let i=0; i<item.diasdasemana.length; i++){
                    
                    //let ind = _.findIndex(diasemana, ['dia', item.diasdasemana[i]]) !== -1

                    for(var a=0; a<diasemana.length; a++){
                        if(item.diasdasemana[i] === diasemana[a].dia){
                            cloned[a].check = true
                        }
                    }

                    //console.log(i, item.diasdasemana[i], ind)

                   
                    
                }

                setDiasemana(cloned)

                setEdit(prevState => ({ ...prevState, 
                    option:true, 
                    item:item, 
                    disponibilidade:item.disponibilidade,
                    destaque:item.destaque,
                    nome:item.nome,
                    descricao:item.descricao,
                    preco:item.preco,
                    precoanterior:item.precoanterior
                } ))
               // console.log(item.diasdasemana)
               // setDiasemana(item)
            }
        }),
    )

    async function onItemClick(e) {
        console.log(e, 'clicked')
        //setDiaescolhido(e.itemData)
        setDiaescolhido(e.itemData.id)
        if(e.itemData.dia !== 'todo o cardápio'){

          await db['itens'].find({
            selector: {
              disponibilidade:{$eq:true},
              diasdasemana: {
                $elemMatch: e.itemData.dia
              }
            }
          }).exec().then(function (result) {
            // list of people shown here
            let newItens = _.clone(JSON.parse(JSON.stringify(result)))
            console.log(newItens)
            //setData(result)

           /* setState(prevState => ({
              ...prevState,
              diaescolhido:e.itemData.id,
              data:result,
              
            }))*/
            
            changeList(newItens)
          });
        }else{
            //setDiaescolhido(0)
            getItems()
        }
      }

    async function salvaDados() {
        let newArray = [];
        _.forOwn(diasemana, (value, key) => {
            if(value.id !== 0 && value.check === true){
                newArray.push(value.dia)
            }
        })

        const doc =  await db['itens'].findOne(item.produtoid).exec()

        console.log(doc.produtoid)
        
        const update = await doc.update({
                $set: {
                    diasdasemana: newArray,
                    disponibilidade:disponibilidade,
                    destaque:destaque,
                    nome:nome,
                    descricao:descricao,
                    preco:preco,
                    precoanterior:precoanterior

                }
            });

        syncDB()
        
        setEdit(prevState => ({ ...prevState, option:false, item:[], disponibilidade:true, destaque:false} ))

        //console.log(newArray, item.produtoid, update)
    }

    function onChange(i) {
        let cloned = _.clone(diasemana)
        _.forOwn(cloned, (value, key) => {
            if(value.dia === i.dia){
                cloned[key].check = !cloned[key].check
            }
        })
        setDiasemana(cloned)
    }

    function clearCheck() {
        let cloned = _.clone(diasemana)
        _.forOwn(cloned, (value, key) => {
            
                cloned[key].check = false
         
        })
        setDiasemana(cloned)
    }

    function setData(e,v){
        //console.log(v)
       
        setEdit(prev => ({ 
         ...prev,
         [v.name]:v.value,
       }));
       
    }

    function apagaItem(){
        //console.log(v)
       
       
    }

    return (
        <div style={{marginBottom:10}}>
            <Divider horizontal>
                <Header as='h4'>
                    Editar cardápio
                </Header>
            </Divider>
            <div style={{display:'flex', flexDirection:'row'}}>
            <div style={{width:60}} className="dx-field-value">
                <DropDownButton
                    useSelectMode={true}
                    dropDownOptions={{ width: 150 }}
                    icon="event"
                    //keyExpr="id"
                    displayExpr="dia"
                    items={semanatoda}
                    selectedItemKey={diaescolhido}
                    onItemClick={onItemClick}
                />
            </div>
            <div style={{fontSize:17, textAlign:'left', alignSelf:'center'}}>{_.capitalize(semanatoda[diaescolhido].dia)}</div>
        </div>
        <Modal
            size={'mini'}
            open={option}
        >
            <Modal.Header>Edite os dados e disponibilidade</Modal.Header>
            {/*<div><Button fluid negative onClick={apagaItem}>
                Apagar
            </Button></div>*/}
            <Modal.Content>
                <Input name='nome' fluid focus placeholder='nome' value={nome} onChange={setData}/>
                <Form><TextArea rows={3} name='descricao' placeholder='descricao' value={descricao} onChange={setData}/></Form>
                <Input name='preco' fluid placeholder='preco' value={preco} onChange={setData}/>
                <Input name='precoanterior' fluid placeholder='preco anterior' value={precoanterior} onChange={setData}/>
                <Divider />
                <div style={{display:'flex', flexDirection:'column'}}>
                {diasemana.filter(item => item.id > 0).map((i, a) => {
                    //console.log(item, i.dia, i.check)
                    return <div key={a} style={{margin:3}}><Checkbox label={_.capitalize(i.dia)} onChange={() => onChange(i)} checked={i.check} /></div>
                })}
                <Divider />
                <div style={{marginTop:3}}><Checkbox label='Disponibilidade' onChange={() => setEdit(prevState => ({ ...prevState, disponibilidade:!disponibilidade} ))} checked={disponibilidade} /></div>
                <div style={{marginTop:3}}><Checkbox label='Destaque' onChange={() => setEdit(prevState => ({ ...prevState, destaque:!destaque} ))} checked={destaque} /></div>
                </div>    
            </Modal.Content>
            <Modal.Actions>
            <Button onClick={() => 
                {
                    setEdit(prevState => ({ ...prevState, option:false} ))
                    clearCheck()
                }}>
                Fechar
            </Button>
            <Button positive onClick={() => salvaDados()}>
                Salvar
            </Button>
            </Modal.Actions>
        </Modal>
        </div>
    )
})

export default Popdisponibilidade