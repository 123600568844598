import React, {useEffect, useContext, useState} from 'react'
import { Dispatch, log, dbName, postServer, useLanguage } from '../hooks/hooks';
import _ from 'lodash';
import { useHistory, useLocation } from "react-router-dom";
//import jwtDecode from 'jwt-decode';
import { ToastContainer, toast, Slide, Flip } from 'react-toastify';
import * as Database from '../database/database';

import notify from 'devextreme/ui/notify';
import 'react-toastify/dist/ReactToastify.css';

import { Image, Modal, Button, Input } from 'semantic-ui-react';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Header = () => {

    const reducer = useContext(Dispatch)
    const [{language, programacao, mensagem, userdata}, dispatch] = reducer;

    const mobile = window.innerWidth<=500?true:false

    const history = useHistory();
    const location = useLocation()
 
    const [popup, setPopup] = useState(false)

    const [dados, setDados] = useState({nome:null, celular:null, loaded:null, token:null, tokenresposta:null})
    const { nome, celular, loaded, token, tokenresposta } = dados

    const [loading, setLoading] = useState(false)

    //const [lang, setLang] = useState('pt')

    const query = useQuery();
    //const [{logged}] = useValidateToken({token, qrtoken:query.get("qrtoken"), dispatch})

    const setLang = () => {
        dispatch({type:'language', payload:language === 'pt'? 'us' : 'pt'})
    }

    const prevLang = () => {
        return language === 'pt'? 'us' : 'pt';
    }

    useEffect( () => {

        async function getDB () {
            console.log(dbName)
            const mydb = await Database.get(dbName);
            dispatch({ type: "db", payload: mydb });
      
        } 

        getDB();

        if(location.pathname === '/takeaway'){
            setPopup(true)
        }
    }, [])

    useEffect( () => {

        if(programacao){
            
            log() && console.log(programacao.data.eventType, programacao.data)

            switch(programacao.data.eventType){
                case 'liveStarted' :
                    if(programacao.data.data.length === 1){
                        history.push({
                            pathname: '/liveevent',
                            url:programacao.data.data[0].url,
                            title:programacao.data.data[0].title,
                            id:programacao.data.data[0].id,
                            live:0
                        })
                    }else{
                        history.push({
                            pathname: '/lobby',
                        })
                    }
                break

                case 'quizStarted' :

                    if(_.isString(programacao.data.data)){
                        toast('Você ja jogou, aguarde os próximos conteúdos', {type: toast.TYPE.SUCCESS, transition: Flip})
                        history.push({
                            pathname: '/idle',
                        })
                    }else if(programacao.data.data.nome === 'Pesquisa'){
                        history.push({
                            pathname: '/pesquisa',
                        })
                    }else{
                        history.push({
                            pathname: '/quiz',
                        })
                    }
                break

                case 'assocStarted' :
                    history.push({
                        pathname: '/associacao',
                    })
                break

                case 'idle' :
                    history.push({
                        pathname: '/idle',
                    })
                break

                case 'ranking' :
                    history.push({
                        pathname: '/ranking',
                    })
                break

                case 'disconnectAll' :
                    localStorage.removeItem('token');
                    document.location.reload(true);
                break

                default :

                break
            }
        }
      
        return () => {}
        
    },[programacao])

    const getContent = () => {
       // return getServer('api/events/now', token)
    }

    useEffect(() => {
        if(mensagem){
            switch(mensagem.type){
                case 'info' :
                    toast(mensagem.text, {type: toast.TYPE.INFO, className: 'rotateY animated'})
                return

                case 'achieve' :
                    toast(mensagem.text, {type: toast.TYPE.SUCCESS, transition: Flip})
                return

                case 'error' :
                    toast(mensagem.text, {type: toast.TYPE.ERROR, transition: Slide})
                return

                default :

                break
            }
        }

    },[mensagem])

   

    useEffect(() => {

        console.log(userdata)

        if(userdata){
            setDados(prev => ({ 
                ...prev,
                nome:userdata.nome,
                loaded:true
              }));
        }

    },[userdata])

    function salva(e, a){
       // console.log(e, a)
        if(a.name === 'nome'){
            setDados(prev => ({ 
                ...prev,
                nome:a.value,
              }));
        }else if(a.name === 'celular'){
            setDados(prev => ({ 
                ...prev,
                celular:Math.max(0, parseInt(a.value) ).toString().slice(0,11)
              }));
        }else{
            setDados(prev => ({ 
                ...prev,
                tokenresposta:a.value.toUpperCase(),
              }));
        }
    }

    async function salvaDados(){

        if(!token){
        
            if(_.isEmpty(nome) || _.isEmpty(celular)){
                toast('Você precisa preencher os dois campos!', {type: toast.TYPE.INFO, transition: Flip})
            }else if(celular.length<11){
                toast('O telefone precisa incluir o DDD!', {type: toast.TYPE.INFO, transition: Flip})
            }else{
                var randomNumber = _.times(1, () => _.times(4, () => _.random(35).toString(36)).join('')).join('-');
                let valNumber = randomNumber.toUpperCase()

                setLoading(true)

                const resposta = await postServer('55'+celular, celular+'_'+valNumber, 'TONHOI Restaurante %0a✅ Token de validação: ' + valNumber )

                if(resposta.val){
                    setDados(prev => ({ 
                        ...prev,
                        token:valNumber,
                    }));

                    //toast('Token enviado! Digite abaixo o código recebido em seu WhatsApp.', {type: toast.TYPE.SUCCESS, transition: Flip})
                    notify('Token enviado! Digite acima o código recebido em seu WhatsApp.', 'info', 3000);
                }else{
                    toast('Número não reconhecido', {type: toast.TYPE.ERROR, transition: Flip})
                }

                setLoading(false)
            }
        }else{
            console.log('validacao:', token, tokenresposta)
            if(token === tokenresposta){
                
                setPopup(false)
                
                localStorage.setItem('dados', [nome, celular]);
                dispatch({type:'userdata', payload:{nome, celular, loaded:true}})
            }else{
                toast('Token incorreto, tente novamente', {type: toast.TYPE.ERROR, transition: Flip})
            }
        }
        

    }

    const goHome = () => {
        history.push({
            pathname: "/",
          });
    }

    return (
        <div>
        <ToastContainer />
        <div className='topMost' style={{backgroundColor:'#7a0d1e', height:mobile?60:80}}>
            <div className='containerFix' style={{ height:'100%', marginLeft:20, marginRight:20 }}>
                <div style={{display:'flex', justifyContent:'space-between', alignContent:'center', alignItems:'center',  height:'100%' }}>
                    <div style={{ height:'30%'  }}>
                        <div onClick={goHome} style={{ height:'100%', fontSize:18, cursor:'pointer' }}>Ton Hoi - Restaurante</div>
                    </div>
                    <div style={{display:'flex', height:'100%', flexDirection:'row', alignItems:'center'}}>
                        <Image src='/images/logo.png' style={{height:'100%'}}/>
                        <button onClick={setLang} style={{height:'100%', border:'none', backgroundColor:'#7a0d1e'}}><Image src={`/images/bandeira-${prevLang()}.png`} style={{height:'30%'}}/></button>
                    </div>
                </div>
            </div>
            
         

        </div>
        {loaded?(
            <div style={{display:'flex',justifyContent:'center', lineHeight:.1}}>
                <div className='containerFix' style={{display:'flex', justifyContent:'flex-end', marginTop:5}}> 
                    <div style={{position:'relative', bottom:-10, fontSize:mobile?12:16, fontFamily:'Oracle Sans Regular', color:'black', marginRight:10}}>Olá <b>{nome}</b></div>
                </div>
            </div>
            ):
        (<Modal
            size={'mini'}
            open={popup}
            
          >
            <Modal.Header>Digite seu nome e telefone celular</Modal.Header>
            <Modal.Content>
            
                <div><p>O número será validado pelo WhatsApp para a confirmação do pedido.</p>
                    <div><Input name='nome' fluid focus placeholder='Nome' onChange={salva}/></div>
                    <div style={{marginTop:5}}><Input name='celular' value={celular?celular:''} type='number' pattern='[0-9]*' inputMode="numeric" focus fluid placeholder='Celular com DDD' onChange={salva}/></div>
                    {token &&
                    <div style={{marginTop:15}}><Input name='token' fluid focus placeholder='Digite aqui o token' onChange={salva} value={tokenresposta?tokenresposta:''} /></div> 
                    }
                </div>    
              
            </Modal.Content>
            <Modal.Actions>
              <Button positive disabled={loading} onClick={salvaDados}>
                {!token?'Salvar':'Verificar'}
              </Button>
            </Modal.Actions>
          </Modal>)}
        </div>
    )
}

export default Header