import React, {useState, useEffect, useContext, useRef} from 'react';

import Lista from '../componentes/Lista' 
import Popuper from '../componentes/Popuper' 

import '@sandstreamdev/react-swipeable-list/dist/styles.css';

import { Dispatch, syncURL } from '../hooks/hooks';

import ptMessages from '../static/pt.json';
import { locale, loadMessages } from 'devextreme/localization';

import { 
  parseISO, 
  format, 
  isBefore
} from 'date-fns';
import {pt} from 'date-fns/locale';

import _ from 'lodash';



export const Takeaway = () => {

    const popup = useRef();

    const reducer = useContext(Dispatch)
    //const db = useContext(DB)
    const [{ db, userdata }, dispatch] = reducer; 

    const [itens, setItens] = useState(null)

    const diasemana = ['todos', 'domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado']

    const [enable, setEnable] = useState(false)

    

    useEffect(() => {
      locale("pt");
      loadMessages(ptMessages);

      document.addEventListener('touchmove', function (event) {
        event.preventDefault();
        if (event.scale !== 1) { event.preventDefault(); }
      }, false);
    },[])

    useEffect(() => {
      console.log('REFRESHED')
      //console.log(db)
      if(db){

        function vai(){

          

          const itensSync = db.itens.sync({
            remote: syncURL,
            direction: {                          // direction (optional) to specify sync-directions
              pull: true, // default=true
              push: false  // default=true
            },
            options: {
                live: false,
                retry: true,
            },
            query: db.itens.find().where('col').eq('itens')
          });

          itensSync.complete$.subscribe(completed => getChanges(itensSync));

          //getChanges()
         /* await db['itens'].find({
            selector: {diasdasemana:{dia: 'sab'}},
          }).exec().then(function (result) {
            // yo, a result
            console.log(result)
          }).catch(function (err) {
            // ouch, an error
            console.log(err)
          });*/

         /* let test = await db['itens'].find({ diasdasemana: { 
            dia: { $eq: 'sab' }  } 
            }).exec().then(result => {
              console.log("result  : ", result);
            })*/

          /*db['itens'].$.subscribe(changeEvent => {
            getChanges() 
            console.log('updated')
          });*/
  

          

          
        }

        async function getChanges(itensSync){

          await itensSync.cancel();
          
          const diadasemana = format(getTodayDate(), 'iiii', {timeZone: 'America/Sao_Paulo', locale:pt});

          await db['itens'].find({
            selector: {
              disponibilidade:{$eq:true},
              diasdasemana: {
                $elemMatch: diadasemana
              }
            }
          }).exec().then(function (result) {
            // list of people shown here
            //console.log(result)
            setItens(result)
          });
        }
        vai()

          /*this.db.albums
          .find()
          .where("artists")
          .elemMatch({ "name": (item) => { return item.match(new RegExp(mySubStrRegEx, "i")); } })
          .exec()*/

        /*const hefes = await db.itens
            .find()
            .where('type')
            .eq('Hefeweizen')
            .exec()*/


        /*db['itens'].find().exec().then(datadoc => {
          console.log(datadoc.length)
          _.forOwn(datadoc, (value, key) => {
            console.log(datadoc[key].id, key)
          })
        })*/
        
      } 
    },[db])

    useEffect(() => {
      
      if(itens){
        setEnable(true)
      }
    },[itens])



  function getTodayDate(){
    const parsedDate1 = parseISO(new Date().toISOString());
    return parsedDate1
}

  /*let MyList = React.memo(props => (
    <Lista opopup={popup} itens={itens} />
  ))*/

    return (
      <React.Fragment>
        {console.log('rendered')}
   
        <div style={{margin:10, height:window.innerHeight-130, marginTop:20}}>
        {itens && userdata &&
         <Lista opopup={popup} itens={itens} />
        }
         </div>
       
        <Popuper ref={popup}/>
        
      </React.Fragment>
    );
  }