import React, {useState, useEffect, useContext, forwardRef, useImperativeHandle, useCallback} from 'react';
import _ from 'lodash';

import { Dispatch } from './../hooks/hooks';

import ScrollView from 'devextreme-react/scroll-view';
import { Image, Segment, Label, Divider, Button as Btn, Icon, Input } from 'semantic-ui-react';
import { hasLanguage } from './Lista';

const formatCurrency = new Intl.NumberFormat(
    'pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  ).format;

const Popuper = forwardRef((props, ref) => {

    const reducer = useContext(Dispatch)
    const [{ pedido, language, db }, dispatch] = reducer; 

    const [selectedItemKeys, setSelectedItemKeys] = useState([-1])
    const [popup, setPopup] = useState('none')
    const mobile = window.innerWidth<500?true:false
    const [currentHotel, setCurrentHotel] = useState(null)
    //const [popup, setPopup] = useState('none')
    const [customPrato, setCustomPrato] = useState({quantidade:1, preco:0, obs:'', currentPrato:null})
   // const {displaym, posY } = showDetails
    const {quantidade, obs, preco, currentPrato } = customPrato

    const { handleListSelectionChange, renderListItem, renderListGroup, listAttrs } = props

    useImperativeHandle(
        ref,
        () => ({
            /*mostraPopup(v) {
                setPopup(v)
            },*/
            escolheItem(item) {
               // console.log(item.produtoid)
                setCustomPrato(prev => ({ 
                    ...prev,
                    currentPrato:item,
                    preco:convertToUSD(item.preco),
                    quantidade:1
                  }));
            }
        }),
    )

    useEffect(() => {
        if(customPrato.currentPrato !== null){
            setPopup('flex')
        }
    
        return () => {
        }
      },[customPrato])

      useEffect(() => {
        if(pedido){
            setPopup('none')
        }
      },[pedido])

    function addQnt(){
        //console.log('press', )
        setCustomPrato(prev => ({ 
          ...prev,
          preco:(quantidade+1) * convertToUSD(currentPrato.preco),
          quantidade:quantidade+1,
        }));
      }
    
      function removeQnt(){
        if(quantidade>1){
          setCustomPrato(prev => ({ 
            ...prev,
            preco:(quantidade-1) * convertToUSD(currentPrato.preco),
            quantidade:quantidade-1,
          }));
        }
      }

      function convertToUSD(valor){
        let tiraponto = _.replace(valor.replace(/[^\d.,-]/g, ''),".","")
        let toUSD = _.replace(tiraponto, ",", ".")
        return toUSD
      }

      const poeCarrinho = useCallback(
        () => {
       // console.log(currentPrato.produtoid)
        //dispatch({type:'addpedido', payload:{porcao:1, obs:null, nome:currentPrato.nome, valor:preco===0?currentPrato.preco:preco}})

        console.log(obs)

        db.carrinho.insert({
            id: currentPrato.produtoid,
            quantidade:quantidade,
            porcao:1,
            obs:obs,
            nome:currentPrato.nome,
            valor:preco===0?currentPrato.preco:preco.toString()
        });

        dismissPopover()

        },
        [currentPrato, quantidade, preco, obs],
    );

      function dismissPopover() {
        setCustomPrato(prev => ({ 
            ...prev,
            currentPrato:null,
            preco:0,
            quantidade:1
          }));
    
        setPopup('none')
        //setIsActionSheetVisible(false)
      }

      function setObs(e, a){
         console.log(a.value, obs)
        
        setCustomPrato(prev => ({ 
          ...prev,
          obs:a.value,
        }));
        
     }
    

    return (
        <div>
        <div onClick={dismissPopover} style={{display:popup, position:'absolute', top:0, backgroundColor:'#ffffff', width:window.innerWidth, height:window.innerHeight, opacity:.8}} />
        <div style={{left:'50%', transform:'translateX(-50%) translateY(-50%)', position:'absolute', top:'50%', width:320}}>
                <div style={{display:popup, backgroundColor:'#ffffff', width:'100%',  maxHeight:window.innerHeight/1.5, boxShadow: "0px 10px 20px 0px #9E9E9E", borderRadius: 5, shadowColor: '#9E9E9E'}}>
                    {popup !== 'none'? <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', width:'100%'}}>
                        
                        <Segment padded style={{height:'100%'}}>
                        
                        <Label attached='top' style={{justifyContent:'space-between', backgroundColor:'#7a0d1e', color:'#ffffff', display:'flex', fontSize:15, height:50}}>{hasLanguage(currentPrato.nome, language)}<Icon onClick={dismissPopover} style={{fontSize:17}} name='close' /></Label>
                       
                        <div style={{paddingTop:10}}>
                            <ScrollView>
                            <Image rounded src={'images/'+currentPrato.galeriadefotos[0]} style={{width:'315px', height:'100px'}}/>
                            <Label style={{marginTop:14, marginLeft:0, backgroundColor:'#7a0d1e', color:'#ffffff'}}>{hasLanguage(currentPrato.categoria, language)}</Label>
                            <Divider />
                            <div style={{marginTop:10}}>{hasLanguage(currentPrato.descricao, language)}</div>
                            
                            </ScrollView>
                            <Divider />
                            {!props.simple && <Input name='obs' fluid focus placeholder='Alguma observação?' onChange={setObs}/> }
                        </div>
                        </Segment>
                        {!props.simple && 
                        <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between', width:'100%'}}>  
                        <Btn.Group style={{height:35, marginBottom:20, marginLeft:12}}>
                            <Btn className='disable-dbl-tap-zoom' onClick={removeQnt} compact style={{fontSize:20, paddingTop:6}}><b>-</b></Btn>
                            <Btn.Or data-label={quantidade}/>
                            <Btn className='disable-dbl-tap-zoom' onClick={addQnt}compact style={{fontSize:20, paddingTop:6}} positive><b>+</b></Btn>
                        </Btn.Group>         
                        <Btn className='disable-dbl-tap-zoom' positive onClick={poeCarrinho} style={{fontSize:13, height:35, marginBottom:12, marginRight:12, marginLeft:5}} type="success">{mobile?'Add':'Adicionar'}<span style={{fontSize:13}}>&nbsp;&nbsp;&nbsp;{formatCurrency(preco)}</span></Btn>
                        </div>
                        }

                    </div>:<div></div>}
                </div>
            </div>
        </div>
    )
})

export default Popuper