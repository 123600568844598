import React, { useState, useEffect, useContext, useRef } from "react";

import Lista from "../componentes/Lista";
import Popuper from "../componentes/Popuper";
import MenuTab from "../componentes/MenuTab";

//import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
import "@sandstreamdev/react-swipeable-list/dist/styles.css";

import { Dispatch, syncURL } from "./../hooks/hooks";

import ptMessages from "../static/pt.json";
import { locale, loadMessages } from "devextreme/localization";
import { Icon } from "semantic-ui-react";

import { parseISO, format, isBefore } from "date-fns";
import { pt } from "date-fns/locale";

import _ from "lodash";

export const Cardapio = () => {
  const popup = useRef();
  const chooseMenu = useRef();

  const reducer = useContext(Dispatch);
  //const db = useContext(DB)
  const [{ db, cardapioitem }, dispatch] = reducer;

  const [itens, setItens] = useState(null);

  const diasemana = [
    "todos",
    "domingo",
    "segunda-feira",
    "terça-feira",
    "quarta-feira",
    "quinta-feira",
    "sexta-feira",
    "sábado",
  ];

  const [selectedItemKeys, setSelectedItemKeys] = useState([-1]);
  const [error, setError] = useState("");
  const [alive, setAlive] = useState("a");

  useEffect(() => {
    locale("pt");
    loadMessages(ptMessages);

    document.addEventListener(
      "touchmove",
      function (event) {
        event.preventDefault();
        if (event.scale !== 1) {
          event.preventDefault();
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    // console.log('REFRESHED')
    //console.log(db)
    if (db) {
      const vai = async () => {
        console.log("||| começa o sync");

        const itensSync = db.itens.sync({
          remote: syncURL,
          waitForLeadership: false,
          direction: {
            // direction (optional) to specify sync-directions
            pull: true, // default=true
            push: false, // default=true
          },
          options: {
            live: false,
            retry: true,
            batch_size: 300,
          },
          query: db.itens.find().where("col").eq("itens"),
        });

        dispatch({ type: "cardapioitem", payload: 0 });

        let done = await itensSync.awaitInitialReplication();

        if (done) {
          getChanges(itensSync);
        }

        /*db['itens'].$.subscribe(changeEvent => {
           // console.log(changeEvent)
            dispatch({type:'cardapioItemAdd'})
          });*/

        //itensSync.complete$.subscribe(completed => getChanges(completed, itensSync));
        itensSync.error$.subscribe((error) => setError(error));
        itensSync.alive$.subscribe((alive) => setAlive(alive));
      };

      async function getChanges(itensSync) {
        // if(completed){

        console.log("--- termina o sync");

        await itensSync.cancel();

        const diadasemana = format(getTodayDate(), "iiii", {
          timeZone: "America/Sao_Paulo",
          locale: pt,
        });

        await db["itens"]
          .find({
            selector: {
              disponibilidade: { $eq: true },
              diasdasemana: {
                $elemMatch: diadasemana,
              },
            },
          })
          .exec()
          .then(function (result) {
            criaDestaques(JSON.parse(JSON.stringify(result)));
          });
      }
      //}

      vai();
    }
  }, [db]);

  const criaDestaques = (itens) => {
    let newItens = _.clone(itens);

    for (let i = 0; i < itens.length; i++) {
      if (
        itens[i].destaque === true ||
        (itens[i].precoanterior !== "" && itens[i].precoanterior)
      ) {
        //console.log(itens.length)
        let newItem = _.clone(itens[i]);
        newItem.categoria = "DESTAQUES";
        newItem.order = 0;
        newItens.push(newItem);
      }
    }
    console.log(newItens);
    setItens(newItens);
  };

  function getTodayDate() {
    const parsedDate1 = parseISO(new Date().toISOString());
    return parsedDate1;
  }

  return (
    <React.Fragment>
      {/*console.log('rendered')*/}

      <div>
        {itens ? (
          <div>
            <MenuTab
              chooseMenu={chooseMenu}
              itens={_.orderBy(itens, "order", "asc")}
            />
            <div
              style={{
                margin: 10,
                height: window.innerHeight - 120,
                marginTop: 0,
              }}
            >
              <Lista
                ref={chooseMenu}
                opopup={popup}
                itens={itens /*_.orderBy(itens, 'categoria', 'asc')*/}
              />
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                height: 9,
                backgroundColor: "#e0a81f",
                width: cardapioitem
                  ? [Math.ceil((cardapioitem * 100) / 223) + "%"]
                  : 0,
              }}
            ></div>
            <div style={{ textAlign: "center" }}>
              Carregando os itens do cardápio aguarde
            </div>
            <div style={{ marginTop: 10, textAlign: "center" }}>
              <Icon name="spinner" size="large" loading />
            </div>
            {/*<div style={{textAlign:'center'}}>{cardapioitem?cardapioitem:1} de 223</div>
            <div>{error}{alive?'!':'.'}</div>*/}
          </div>
        )}
      </div>

      <Popuper ref={popup} simple={true} />
    </React.Fragment>
  );
};
