import React, {useState, useEffect, useLayoutEffect} from "react";
import axios from 'axios';
import _ from 'lodash';
import jwtDecode from 'jwt-decode';

//const localtoken = localStorage.getItem('token');
const httpPath = 'https://'
const couchUrl = process.env.REACT_APP_URL
const username = 'antidoto';
const password = 'virus';
export const dbName = process.env.REACT_APP_DB;

export const syncURL = httpPath+username+':'+password+'@'+couchUrl + dbName


export const Dispatch = React.createContext('Dispatch');
export const DB = React.createContext('DB');

const showLog = false
const cel = '5511999948369'
const baseURL = 'https://www.waboxapp.com/api/send/chat?token=164e13aeb667165b55353e81c8fe2b3e5f88820377b96&uid='+cel+'&to=';

export function logginReducer(state, action) {
    showLog && console.log('reducer', 'state:', state, 'action:', action)
    switch(action.type){
      case 'language' : {
        return { 
            ...state,
            language:action.payload
        }
    }
        case 'validated' : {
            return { 
                ...state,
                validated:action.payload
            }
        }
        case 'logout' : {
            return {
                ...state,
                validated:null
            }
        }
        case 'token' : {
            return {
                ...state,
                token:action.payload
            }
        }
        case 'area' : {
            return {
                ...state,
                area:action.payload
            }
        }
        case 'userdata' : {
            return {
                ...state,
                userdata:action.payload
            }
        }
        case 'mensagem' : {
            return {
                ...state,
                mensagem:action.payload
            }
        }
        case 'popoverdismiss' : {
            return {
                ...state,
                popoverdismiss:action.payload
            }
        }
        case 'removepedido' : {
          return {
              ...state, // copy the state (level 0)
              pedido: _.omit(state.pedido, action.payload.id),
            }
        }

        case 'limpapedido' : {
          return {
              ...state, // copy the state (level 0)
              pedido: [],
            }
        }
       
        case 'addpedido' : {
            return {
                ...state, // copy the state (level 0)
                pedido: {
                  ...state.pedido, // copy the nested object (level 1)
                  [action.payload.id]: {  // update one specific house (using Computed Property syntax)
                    ...state.pedido[action.payload.id],  // copy that specific house's properties
                    id:action.payload.id,
                    nome: action.payload.nome,
                    valor: action.payload.valor,
                    quantidade: action.payload.quantidade,
                    porcao: action.payload.porcao,
                    obs: action.payload.obs   // update its `points` property
                  }
/*
                  pessoas: action.payload.pessoas,
                  id:action.payload.id*/
                }
              }
        }
        case 'db' : {
            return {
                ...state,
                db:action.payload
            }
        }
        case 'popup' : {
          return {
              ...state,
              popup:action.payload
          }
        }
        case 'cardapioItemAdd' : {
          return {
              ...state,
              cardapioitem:state.cardapioitem+1
          }
        }
        case 'cardapioitem' : {
          return {
              ...state,
              cardapioitem:action.payload
          }
        }
        default : {
            return state     
        } 
    }
}

export const syncItens = async (db) => {
  //return new Promise((resolve, reject) => {
      const repState = db.itens.sync({
          remote: httpPath+username+':'+password+'@'+couchUrl + dbName,
          options: {
              live: false,
              retry: true,
          },
          query: db.itens.find().where('col').eq('itens')
       });

       let done = await repState.awaitInitialReplication();

       return done
 // })
}

export const syncConfig = async (db) => {
  //return new Promise((resolve, reject) => {
      const repState = db.config.sync({
          remote: httpPath+username+':'+password+'@'+couchUrl + dbName,
          options: {
              live: false,
              retry: true,
          },
          query: db.config.find().where('col').eq('config')
       });

       let done = await repState.awaitInitialReplication();

       return done
 // })
}



export function useFetchData(url, timeout) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
  
    function init() {
      setData([]);
      setLoading(true);
      setLoading(false)
    }
  
    async function load() {
      init();
      setLoading(true);
      try {
        const result = await axios.fetch(url, {timeout: timeout}).data;
        setData(result);
      } catch (e) {
        setError(true);
      }
      setLoading(false);
    }
  
  return {data, loading, error, load};
  }

  export function useValidateToken ({token, qrtoken, dispatch}){
    const { log } = useLog();
    const [logged, setLogged] = useState(false);
    //console.log(token, qrtoken) 
    async function validate() {
        log && console.log('dentro', token, qrtoken) 
        let valid = await validateLocalToken(token);
        if(!valid){
            const invalid = await validateServerToken(qrtoken, dispatch)
            if(!invalid){
                setLogged(null)
            }else{
                //setLogged(true)
            }
        }else{
           // dispatch({type:'validated', payload:true})
            setLogged(true)
        }
    }

    useEffect(() => {
        if(token !== false) validate();
      }, [token]);

    return [{logged, validate}];
  }

  const validateLocalToken = async (token) => {

    return new Promise((resolve, reject) => {
        
        if(_.isNil(token)){
            resolve(false)
         }else if(token){
            
            resolve(true)
        }else{

        }
    })
   
}

const validateServerToken = async (qrtoken, dispatch) => {
    return new Promise((resolve, reject) => {
       // let webToken = query.get("qrtoken")
        try{
            var decoded = jwtDecode(qrtoken);
        }catch(err){showLog && console.log('erro decode token'); resolve(false)}

        if(!decoded){
            resolve(false)
        }else{
            localStorage.setItem('token', qrtoken);

            setTimeout(() => {
                dispatch({type:'token', payload:qrtoken})
                resolve(true)
            },1000)
            
        }
    })
}

export function useDimensions (targetRef, active) {
    const getDimensions = () => {
      return {
        width: targetRef.current ? targetRef.current.offsetWidth : 0,
        height: targetRef.current ? targetRef.current.offsetHeight : 0
      };
    };
  
    const [dimensions, setDimensions] = useState(getDimensions);
  
    const handleResize = () => {
      setDimensions(getDimensions());
    };
  
    useEffect(() => {
        if(active){
            window.addEventListener("resize", handleResize);
        }else{
            window.removeEventListener("resize", handleResize);
        }
      return () => window.removeEventListener("resize", handleResize);
    }, [active]);
  
    useLayoutEffect(() => {
      handleResize();
    }, []);
    return dimensions;
  }

  export const postServer = async (numero, id, text) => {
        
    return new Promise((resolve, reject) => {

       /* let config = {
            headers: {
                Authorization: 'Bearer ' + (localtoken?localtoken:token),
            }
        }*/

        console.log(numero, id, text)

        return axios
        .post(baseURL+numero+'&custom_uid='+id+'&text='+text/*, data, config*/)
        .then(response => {return resolve({val:true, token:response})})
        .catch(error => {console.log('erro:', error.response); return resolve({val:false, token:error.response})});

    })
}

export const postServerLogin = async (api, data) => {
    return new Promise((resolve, reject) => {

        return axios
        .post(baseURL+api, data)
        .then(response => {return resolve({val:true, token:response})})
        .catch(error => {console.log('erro:', error.response); return resolve({val:false, token:error.response})});

    })
}

export const getServer = async (api, token) => {

        showLog && console.log(token)

        return new Promise((resolve, reject) => {

            let config = {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            }

        return axios
        .get(baseURL+api, config)
        .then(response => {return resolve({val:true, token:response})})
        .catch(error => {console.log('erro:', error.response); return resolve({val:false, token:error.response})});
        })

}


  export function verificarCPF(c){
    var i;
    var s = c.cpf;
    var c = s.substr(0,9);
    var dv = s.substr(9,2);
    var d1 = 0;
    var v = false;
 
    for (i = 0; i < 9; i++){
        d1 += c.charAt(i)*(10-i);
    }
    if (d1 == 0){
        //alert("CPF Inválido1")
        v = true;
        return false;
    }
    d1 = 11 - (d1 % 11);
    if (d1 > 9) d1 = 0;
    if (dv.charAt(0) != d1){
       // alert("CPF Inválido2")
        v = true;
        return false;
    }
 
    d1 *= 2;
    for (i = 0; i < 9; i++){
        d1 += c.charAt(i)*(11-i);
    }
    d1 = 11 - (d1 % 11);
    if (d1 > 9) d1 = 0;
    if (dv.charAt(1) != d1){
       // alert("CPF Inválido3")
        v = true;
        return false;
    }
    if (!v) {
        return true
    }
}

export const useTimer = () => {
    const [inicial, setInicial] = useState(0);
    const [inverse, setInverse] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(inicial);
  
    useEffect(
      () => {
          
        let interval;
        if (isRunning) {
          interval = setInterval(
            () => setElapsedTime(prevElapsedTime => inverse?prevElapsedTime -.1: prevElapsedTime + .1),
            100
          );
        }
        return () => clearInterval(interval);
      },
      [isRunning]
    );

    useEffect(
        () => {
            setElapsedTime(inicial)
        },
        [inicial]
    );
  
    return {
      setInverse,
      setInicial,
      isRunning,
      setIsRunning,
      elapsedTime,
      setElapsedTime
    };
  };
  
  export const useStopwatch = () => {
    const [laps, setLaps] = useState([]);
    const { isRunning, setIsRunning, elapsedTime, setElapsedTime, setInverse, setInicial } = useTimer();
  
    const handleReset = () => {
      setIsRunning(false);
      setElapsedTime(0);
      setLaps([]);
    };
  
    const handleAddLap = () => {
      const prevTotal =
        laps.length > 0 ? laps.reduce((acc, curr) => acc + curr, 0) : 0;
      const currentLap = laps.length > 0 ? elapsedTime - prevTotal : elapsedTime;
      isRunning && setLaps([...laps, currentLap]);
    };
  
    return {
      setInicial:(e) => setInicial(e),
      setInverse:(e) => setInverse(e),
      elapsedTime: elapsedTime.toFixed(1),
      laps,
      addLap: () => handleAddLap(),
      resetTimer: () => handleReset(),
      startTimer: () => setIsRunning(true),
      stopTimer: () => setIsRunning(false),
      isRunning
    };
  };

  export function log(){
    return true

}

export const useLog = () => {
    const [log, setLog] = useState(false);

    useEffect(
        () => {
           
        },
        [log]
    );
  
    return {
        log
    };
  };