import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "semantic-ui-css/semantic.min.css";
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';


ReactDOM.render(
  <Router>
      <App />
  </Router>, 
  document.getElementById('root')
)

