import React, {useState, useEffect, useContext, useRef} from 'react';
import { Form, Button, Divider, Header } from 'semantic-ui-react'
import { Dispatch, syncItens, syncConfig } from './../hooks/hooks';
import { toast, Slide, Flip } from 'react-toastify';
import { ConfigSet } from '../componentes/ConfigSet'

export const Dump = () => {

    const reducer = useContext(Dispatch)
    const [{ db }, dispatch] = reducer; 

    const [state, setState] = useState({dumpText:''});
    const {dumpText} = state;

    useEffect(() => {
        if(db){
            const synca = async () => {
                const syncDone = await syncItens(db) 
                console.log(syncDone)

                const verConfig = await syncConfig(db)
                console.log(verConfig)
            }

            synca()
        }
        
        return () => {}
    },[db])

    const backupCardapio = async (e) => {
        db['itens'].dump().then((json) => setState((prevState) => ({
          ...prevState,
          dumpText: JSON.stringify(json),
        })));
      };
    
      const injetaCardapio = async () => {
          console.log(dumpText)
        if(dumpText !== ''){
            db['itens']
            .importDump(JSON.parse(dumpText))
            .then(() => console.log("importado"));

            const syncDone = await syncItens(db) 
            console.log(syncDone)
            
        }else{
            console.log('Precisa de um JSON com o backup')
        }
      };

      const changeTextArea = (e, { value }) => {
          console.log(value)
        setState((prevState) => ({
            ...prevState,
            dumpText: value,
          }))
      }

      const forceEachItem = async () => {
          let toJSON = JSON.parse(dumpText)
        if(dumpText !== ''){
            db['itens'].bulkInsert(toJSON.docs)
            
            const syncDone = await syncItens(db) 
            console.log(syncDone)
            toast('Import completado!', {type: toast.TYPE.SUCCESS, transition: Flip})
            
        }else{
            console.log('Precisa de um JSON com o backup')
        }
        };
    

      
    return (
        <div style={{justifyContent:'flex-start', alignContent:'center', display:'flex', flexDirection:'column', alignItems:'center', alignSelf:'center'}}>
            <div style={{width:'70%'}}>
            <Divider horizontal>
                <Header as='h4'>
                    Inserir configurações da empresa
                </Header>
            </Divider>
            <div>
                Horários de atendimento
            </div>
            <ConfigSet />
            <Divider horizontal>
                <Header as='h4'>
                    Manutenção da lista de itens
                </Header>
            </Divider>
            <div>DUMP</div>
            <Form>
                <Button onClick={backupCardapio}>Backup</Button>
                <Form.TextArea label='About' placeholder='Dump da collection' value={dumpText} onChange={changeTextArea}/>
                <Button onClick={injetaCardapio} primary>Restaurar Backup</Button>
                <Button onClick={forceEachItem} primary>Forçar cada item</Button>
            </Form>
            </div>
        </div>
    )
}