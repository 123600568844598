import React, { useState, useContext, useEffect } from "react";
import TabPanel from "devextreme-react/tab-panel";
import DateBox from "devextreme-react/date-box";
import { Button } from "semantic-ui-react";
import { Dispatch, syncConfig } from "../hooks/hooks";
import _ from "lodash";

const companyname = process.env.REACT_APP_COMPANYNAME;

export const ConfigSet = () => {
  const reducer = useContext(Dispatch);
  const [{ db }] = reducer;

  const [state, setState] = useState({
    data: [],
    index: 0,
  });
  const { data, index, selectedIndex } = state;

  useEffect(() => {
    if (db) {
      const getConfig = async () => {
        const dataFunc = await getFuncionamento();
        if (dataFunc) {
            console.log(JSON.parse(JSON.stringify(dataFunc)))
          setState((prevState) => ({
            ...prevState,
            data: JSON.parse(JSON.stringify(dataFunc.funcionamento)),
          }));
        }
      };
      getConfig();
    }
    return () => {};
  }, [db]);

  const getFuncionamento = async () => {
    const getCompanyConfig = await db["config"].findOne(companyname).exec();
    return getCompanyConfig;
  };

  const itemTitleRender = (data) => {
    return <span>{data.diasemana}</span>;
  };

  const onSelectionChanged = (args) => {
    if (args.name == "selectedIndex") {
      setState((prevState) => ({
        ...prevState,
        index: args.value,
      }));
    }
  };

  function setData(e, diasemana, i, tipo) {
    //console.log(e, diasemana, i, tipo);

    //let dataClone = _.clone(data);

    var newArr = _.map(data, function (a) {
      
      if(a.diasemana === diasemana){
        let newHorarios = a.horarios;
        newHorarios[i][tipo] = e.value;
        return { diasemana: diasemana, horarios: newHorarios }
      }else{
        return a
      }
      /*return a.diasemana === diasemana
        ? { diasemana: diasemana, horarios: (a.horarios[i][tipo] = e.value) }
        : a;*/
    });

    //let newData = data;
    //newData[id].horarios[i][tipo] = e.value

    setState((prevState) => ({
      ...prevState,
      data: newArr,
    }));

    //console.log(newArr);
  }

  const CompanyItem = ({ data }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <div>
          {data.horarios.map((a, i) => {
            return (
              <div
                key={i}
                style={{ display: "flex", alignItems: "center", margin: 5 }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ marginRight: 10 }}>Das</div>

                  <DateBox
                    pickerType="rollers"
                    defaultValue={a.das}
                    displayFormat="HH:mm"
                    type="time"
                    onValueChanged={(e) => setData(e, data.diasemana, i, "das")}
                    applyValueMode="useButtons"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <div style={{ marginRight: 10, marginLeft: 10 }}>até</div>

                  <DateBox
                    pickerType="rollers"
                    defaultValue={a.ate}
                    displayFormat="HH:mm"
                    type="time"
                    onValueChanged={(e) => setData(e, data.diasemana, i, "ate")}
                    applyValueMode="useButtons"
                  />
                </div>
                <div style={{ marginLeft: 10 }}>
                  <Button primary onClick={() => apagar(i)}>
                    Apagar
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const addHorario = () => {
    if (data[index].horarios.length < 4) {
      let newData = data;
      newData[index].horarios.push({ das: "00:00", ate: "00:30" });
      setState((prevState) => ({
        ...prevState,
        data: newData,
      }));
    }
  };

  const apagar = (i) => {
    const newData = [...data];
    newData[index].horarios.splice(i, 1);
    setState((prevState) => ({
      ...prevState,
      data: newData,
    }));
  };

  const salvar = async () => {
    const getCompanyConfig = await getFuncionamento();
    //console.log(getCompanyConfig.funcionamento);
    if (getCompanyConfig) {
      await getCompanyConfig.update({
        $set: {
            funcionamento: data,
        }
    });
    } else {
      const save = await db["config"].insert({
        estabelecimento: companyname,
        funcionamento: data,
      });
      console.log(save);
    }

    const verConfig = await syncConfig(db);
    console.log("atualizado:", verConfig);
  };

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginBottom: 5 }}
      >
        <Button primary onClick={addHorario}>
          Adicionar novo horário
        </Button>
      </div>
      <TabPanel
        height={210}
        dataSource={data}
        selectedIndex={index}
        onOptionChanged={onSelectionChanged}
        itemTitleRender={itemTitleRender}
        itemComponent={CompanyItem}
        animationEnabled={true}
        swipeEnabled={true}
        deferRendering={true}
      />
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 5 }}
      >
        <Button positive onClick={salvar}>
          Salvar
        </Button>
      </div>
    </div>
  );
};
