import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import List from "devextreme-react/list";
import ArrayStore from "devextreme/data/array_store";
//import Tabs from 'devextreme-react/tabs';

import {
  Image,
  Segment,
  Label,
  Divider,
  Button as Btn,
} from "semantic-ui-react";

/*import { 
    parseISO, 
    format, 
    isBefore
  } from 'date-fns';
import {pt} from 'date-fns/locale';*/

import _ from "lodash";

import { Dispatch } from "./../hooks/hooks";
import { changeLanguage } from "./MenuTab";

const listAttrs = { class: "list" };
const formatCurrency = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format;

export function hasLanguage(name, language) {
  if (name.indexOf("@") !== -1 && language === "pt") {
    return name.substr(0, name.indexOf("@"));
  } else {
    if (name.indexOf("@") === -1) {
      return name;
    } else {
      return name.substr(name.indexOf("@") + 1, name.length);
    }
  }
}

const Lista = forwardRef((props, ref) => {
  //render(props) {
  const { opopup, itens, clickItem } = props;
  console.log("Lista => I'm rendering...");

  const mobile = window.innerWidth < 500 ? true : false;

  const reducer = useContext(Dispatch);
  const [{ pedido, language }, dispatch] = reducer;

  const scrollref = useRef();

  const [scrollPos, setScrollPos] = useState(null);

  const langArray = itens.map((obj) => {
    return { ...obj, categoria: changeLanguage(language, obj.categoria) };
  });

  const dataSourceOptions = {
    store: new ArrayStore({
      data: langArray,
      key: "produtoid",
    }),
    sort: { field: "nome", desc: false },
    paginate: false,

    postProcess: function (data) {
      let sortedArray = data.sort(sortAlphaNum);
      return sortedArray;
    },

    group: { selector: "categoria", desc: true },
    searchExpr: ["nome", "descricao", "ingredientes"],
  };

  function sortAlphaNum(a, b) {
    if (parseInt(a.items[0].order) < parseInt(b.items[0].order)) {
      return -1;
    } else {
      return 0;
    }
  }

  useImperativeHandle(ref, () => ({
    /*mostraPopup(v) {
                  setPopup(v)
              },*/
    chooseMenu(args) {
      //console.log("PRESSED", args);
      onValueChanged(args);
    },
  }));

  useEffect(() => {
    //getUniqueTabs()

    if (scrollref.current) {
      //scrollref.current.instance.scrollTo(xScroll)
    }
  }, []);

  useEffect(() => {
    /* console.log(scrollPos,  _.isEmpty(pedido))
            if(!_.isEmpty(pedido)){
                setTimeout(() => {
                   // console.log('foi', scrollPos)
                    scrollref.current.instance.scrollTo(xScroll)
                }, 500)
                
            }*/
  }, [pedido, scrollPos]);

  function handleListSelectionChange(e) {
    let prato = e.addedItems[0];

    if (prato) {
      /*setCurrentHotel(e.addedItems[0]);
              setSelectedItemKeys([e.addedItems[0].id])
              setCustomPrato(prev => ({ 
                ...prev,
                preco:convertToUSD(e.addedItems[0].preco),
              }));*/
      //setShowCancelButton(false)
      // popup.current.mostraPopup('flex')
      if (opopup.current) {
        opopup.current.escolheItem(prato);
      } else {
        opopup(prato);
      }
      //dispatch({type:'popup', payload:'none'})
    }
  }

  function convertToUSD(valor) {
    let tiraponto = _.replace(valor.replace(/[^\d.,-]/g, ""), ".", "");
    let toUSD = _.replace(tiraponto, ",", ".");
    return toUSD;
  }

  function calculatePercent(a, b) {
    let menos = convertToUSD(b) - convertToUSD(a);
    let calc = Math.floor((menos * 100) / convertToUSD(b));
    return calc;
  }

  function renderListGroup(group) {
    // console.log(group)
    return <div style={{ color: "#ffffff" }}>{group.key}</div>;
  }

  function renderListItem(item) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", width: "75%" }}>
          <div className={"myClip2"} style={{ fontSize: 13 }}>
            <b>{item?.nome && hasLanguage(item.nome, language)}</b>
          </div>

          <div className={"myClip"}>{`${
            item?.descricao && hasLanguage(item.descricao, language)
          }`}</div>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ fontSize: 16, marginTop: 5, alignSelf: "center" }}>
              {item?.preco && formatCurrency(convertToUSD(item.preco))}
            </div>{" "}
            &nbsp;&nbsp;&nbsp;
            {!_.isEmpty(item.precoanterior) && (
              <div style={{ marginTop: 5 }}>
                <span style={{ fontSize: 14, textDecoration: "line-through" }}>
                  {item?.precoanterior &&
                    formatCurrency(convertToUSD(item.precoanterior))}
                </span>
                &nbsp;&nbsp;
                <Label style={{ fontSize: 11 }} as="a" color="teal" tag>
                  -
                  {item?.precoanterior &&
                    calculatePercent(item.preco, item.precoanterior)}
                  %
                </Label>
              </div>
            )}
          </div>
        </div>
        <div
          style={{ display: "flex", width: "25%", justifyContent: "flex-end" }}
        >
          <Image
            rounded
            src={"/images/" + item.foto}
            style={{ width: "65px", height: "65px" }}
          />
        </div>
      </div>
    );
  }

  function scrolando(e) {
    //console.log(scrollref.current.instance.scrollTop())
    setTimeout(() => {
      //setXscroll(scrollref.current.instance.scrollTop())
    }, 500);
  }

  function onValueChanged(args) {
    scrollref.current.instance.scrollTo(0);

    if (args.itemData.id >= 2) {
      scrollref.current.instance.scrollToItem({
        group: args.itemData.id,
        item: 1,
      });
      scrollref.current.instance.scrollBy(window.innerHeight - 390);
    } else if (args.itemData.id === 1) {
      scrollref.current.instance.scrollBy(300);
    }
  }

  return (
    <Fragment>
      <List
        ref={scrollref}
        selectionMode="single"
        dataSource={dataSourceOptions}
        grouped
        // onContentReady={goToBottom}
        searchEnabled
        selectedItemKeys={[-1]}
        onSelectionChanged={handleListSelectionChange}
        itemRender={renderListItem}
        groupRender={renderListGroup}
        elementAttr={listAttrs}
        hoverStateEnabled={false}
        noDataText={"Carregando o cardápio aguarde..."}
        //onItemClick={clickItem}
        showScrollbar="onHover"
        useNativeScrolling={true}
        //onScroll={scrolando}
        collapsibleGroups={false}
        onItemClick={scrolando}
        searchTimeout={500}
        // height={window.innerHeight-130}
      />
    </Fragment>
  );
});
//}

export default React.memo(Lista);
