import React, { Fragment, useState, useRef, useEffect, useContext } from 'react';
import Lista from '../componentes/Lista' ;
import Popdisponibilidade from '../componentes/Popdisponibilidade';
import PopAddPrato from '../componentes/PopAddPrato';
import OrdenarLista from '../componentes/OrdenarLista';
import { Input } from 'semantic-ui-react';

import _ from 'lodash';


import { Dispatch, syncURL } from './../hooks/hooks';

const listAttrs = { class: 'list' };
const formatCurrency = new Intl.NumberFormat(
  'pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
).format;

const localtoken = localStorage.getItem('token');

export const Cardapioadm = () => {

    //render(props) {
        //const { opopup, dataSourceOptions, clickItem, renderListGroup, setXscroll, xScroll } = props
        //console.log("I'm rendering... ADM")
        const popup = useRef();

        const reducer = useContext(Dispatch)
        const [{ db }] = reducer; 

        //const [data, setData] = useState([]);
        //const [diasemana, setDiasemana] = useState([{id:1, dia:'todos', check:false}, {id:2, dia:'domingo', check:false}, {id:3, dia:'segunda-feira', check:false}, {id:4, dia:'terça-feira', check:false}, {id:5, dia:'quarta-feira', check:false}, {id:6, dia:'quinta-feira', check:false}, {id:7, dia:'sexta-feira', check:false}, {id:8, dia:'sábado', check:false}])
        //const [diaescolhido, setDiaescolhido] = useState('todos')

        const [state, setState] = useState({popAddOpen:false, rxdocList:[], data:[], logged:false, pass:'', token:localtoken});
        const {popAddOpen, rxdocList, data, logged, pass, token} = state;

        useEffect(() => {
          if(token && token === 'userloggedtrue'){
            setState(prev => ({ 
              ...prev,
              logged:true,
            }));
          }
        
        },[token])

        useEffect(() => {

          if(pass === 'johnytonhoi'){
            localStorage.setItem('token', 'userloggedtrue');
            setState(prev => ({ 
              ...prev,
              logged:true,
            }));
          }

        },[pass])

        useEffect(() => {
          console.log('REFRESHED')
          //console.log(db)
          if(db){
    
            const vai = async () =>{

              console.log('||| começa o sync')
    
              const itensSync = db.itens.sync({
                remote: syncURL ,
                waitForLeadership: false,
                direction: {                          // direction (optional) to specify sync-directions
                  pull: true, // default=true
                  push: false  // default=true
                },
                options: {
                    live: false,
                    retry: true,
                    batch_size:300,
                },
                query: db.itens.find().where('col').eq('itens')
              });

              let done = await itensSync.awaitInitialReplication();
    
              if(done){
                getChanges(itensSync)
              }
            }
    
            async function getChanges(itensSync){
    
              await itensSync.cancel();

              syncDB()

              getItems()
            }
            vai()
          } 
        },[db])

        function syncDB(){
          db.itens.sync({
            remote: syncURL ,
            direction: {                          // direction (optional) to specify sync-directions
              pull: false, // default=true
              push: true  // default=true
            },
            options: {
                live: false,
                retry: true,
            },
            query: db.itens.find().where('col').eq('itens')
          });
        }

          async function getItems(){
            await db['itens'].find().exec().then(result =>  {
                criaDestaques(result)
              });
          }

          const criaDestaques = (itens) => {

            let newItens = _.clone(JSON.parse(JSON.stringify(itens)))
            
            for(let i=0; i<itens.length; i++ ) {
              if(itens[i].destaque === true || (itens[i].precoanterior !== '' && itens[i].precoanterior)){
                //console.log(itens.length)
                let newItem = _.clone(newItens[i])
                newItem.categoria = 'DESTAQUES';
                newItem.order = 0;
                newItens.push(newItem)
              }
            }
            console.log(newItens)

            setState(prevState => ({ ...prevState, data:newItens, rxdocList:itens}))
          }

          function editItem(e){
            popup.current.openPopup(e)
          }

          function changeList(data){
           // console.log(data)
           setState(prevState => ({ ...prevState, data}))
          }

          function setData(e,v){
            //console.log(v)
           
            setState(prev => ({ 
             ...prev,
             [v.name]:v.value,
           }));
           
        }

        return (
            <Fragment>
              {! logged? <div style={{width:'100%', justifyContent:'center', display:'flex', marginTop:10}}><Input name='pass' focus placeholder='senha' value={pass} onChange={setData}/></div> : <div>
              <div style={{justifyContent:'flex-start', alignContent:'center', display:'flex', flexDirection:'column', alignItems:'center', alignSelf:'center'}}>
              <div style={{display:'flex', justifyContent:'center', flexDirection:'column', margin:10, width:'70%'}}>
                <h3>Sistema para edição do cardápio</h3>
                <OrdenarLista data={rxdocList}/>
                <PopAddPrato open={popAddOpen} db={db} itens={data} changeList={changeList} getItems={getItems} syncDB={syncDB}/>
                <Popdisponibilidade ref={popup} db={db} changeList={changeList} getItems={getItems} syncDB={syncDB}/>
                <Lista opopup={editItem} itens={data}/>
                </div>
                </div>
                </div>}
            </Fragment>
        )
    }
//}
