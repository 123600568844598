import React, { useState } from "react";
import clsx from "clsx";

const HorizontalScroll = ({ className, children }) => {
  const scrollRef = React.useRef(null);
  const [grab, setGrab] = useState(false);

  let initialPosition = { scrollLeft: 0, x: 0 };

  const mouseDown = (event) => {
    if (!grab && scrollRef.current !== null) {
      //console.log("drag", scrollRef.current?.scrollLeft, event.clientX);
      setGrab(true);
      initialPosition.scrollLeft = scrollRef.current?.scrollLeft ?? 0;
      initialPosition.x = event.clientX;
      /*console.log(
        `initialPosition.scrollLeft: ${initialPosition.scrollLeft} initialPosition.x: ${initialPosition.x}`
      );*/
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
      window.addEventListener("touchmove", touchMove);
      window.addEventListener("touchend", mouseUp);
    }
  };
  const mouseMove = (event) => {
    if (
      scrollRef.current !== null &&
      scrollRef.current.classList.contains("horizontal-scroll--dragging")
    ) {
      //console.log("dragmove", initialPosition.scrollLeft);
      const isStart = scrollRef.current.scrollLeft === 0;
      const isEnd =
        scrollRef.current.scrollLeft + scrollRef.current.clientWidth ===
        scrollRef.current.scrollWidth;
      const direction =
        event.clientX - initialPosition.x < 0 ? "right" : "left";
      /*console.log(
        `isStart: ${isStart} isEnd: ${isEnd} direction: ${direction}`
      );*/
      if (isStart && direction === "left") {
        scrollRef.current.style.setProperty(
          "--scroll-band",
          10 + Math.round((event.clientX - initialPosition.x) / 50) + "px"
        );
      } else if (isEnd && direction === "right") {
        scrollRef.current.style.setProperty(
          "--scroll-band",
          10 - Math.round((event.clientX - initialPosition.x) / 50) + "px"
        );
      } else {
        scrollRef.current.style.setProperty("--scroll-band", "10px");
      }
      scrollRef.current.scrollLeft =
        initialPosition.scrollLeft - (event.clientX - initialPosition.x);
    }
  };
  const mouseUp = () => {
    if (scrollRef.current == null) return;
    //console.log("dragrelease");
    setGrab(false);
    window.removeEventListener("mousemove", mouseMove);
    window.removeEventListener("mouseup", mouseUp);
    window.removeEventListener("touchmove", touchMove);
    window.removeEventListener("touchend", mouseUp);
    scrollRef.current.style.setProperty("--scroll-band", "10px");
  };
  const touchMove = (event) => mouseMove(event.touches[0]);
  const touchStart = (event) => mouseDown(event.touches[0]);

  return (
    <div
      ref={scrollRef}
      onMouseDown={mouseDown}
      onTouchStart={touchStart}
      className={clsx(
        className,
        "horizontal-scroll--hide-scrollbars",
        grab ? "horizontal-scroll--dragging" : "horizontal-scroll--release"
      )}
    >
      {children}
    </div>
  );
};

export default HorizontalScroll;
