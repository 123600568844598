import React, { useState, useEffect, useContext, useRef } from "react";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Calendar from 'devextreme-react/calendar';
import SelectBox from 'devextreme-react/select-box';
import { TagBox } from 'devextreme-react/tag-box';
import DataSource from 'devextreme/data/data_source';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { Dispatch } from "../hooks/hooks";

import { parseISO, format, isBefore } from "date-fns";

import ptMessages from '../static/pt.json';
import { locale, loadMessages } from 'devextreme/localization';

import _ from "lodash";

const theme = createMuiTheme({
  palette: {
      primary: { main: "#7a0f1e" },
      secondary: { main: "#5F7D8E" },
      error: { main: "#f44336" }
  },
  overrides: {
    // Nome da folha de estilo ⚛️
    MuiButton: {
      // Nome da regra
      text: {
        // Algum CSS
        color: '#000000',
      },
    },
    MuiStepLabel:{
      label:{
        fontSize: '1.1rem',
      },
      active:{
        fontSize: '1.3rem',
      },
      completed:{
        fontSize: '.8rem',
        color: '#00ff00',
        opacity:.5,
      }
    },
    MuiStepIcon:{
      text: {
        // Algum CSS
        
        color: '#00ff00',
      },
      active:{
        fontSize: '3rem',
        color: '#00ff00',
        marginLeft:-9,
      },
      completed:{
       
        color: '#00ff00',

      }
    }
  },
  typography: {
    button: {
      fontSize: '1rem',
    },
    
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));


function getSteps() {
  return [{label:'Escolha o dia e o horário', color:'red'}, {label:'Selecione a quantidade de lugares'}, {label:'Resumo da sua reserva'}];
}

const isWeekend = (date) => {
  const day = date.getDay();
  return day === 1 || day === 2;
}



const onCurrentValueChanged = (e) => {
  console.log(e.value);
}

const horarios = [
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  
];

const mesas = [{
  'ID': 1,
  'Name': '2 lugares',
  'Category': 'Mesa com 2 Lugares - 2 disponíveis'
}, {
  'ID': 2,
  'Name': '2 lugares',
  'Category': 'Mesa com 2 Lugares - 2 disponíveis'
}, {
  'ID': 3,
  'Name': '4 lugares',
  'Category': 'Mesa com 4 Lugares - 2 disponíveis'
}, {
  'ID': 4,
  'Name': '4 lugares',
  'Category': 'Mesa com 4 Lugares - 2 disponíveis'
},
]

const lugares = new DataSource({
  store: mesas,
  key: 'ID',
  group: 'Category'
});

export const Reserva = () => {
  const popup = useRef();

  const reducer = useContext(Dispatch);
  const [{ db }, dispatch] = reducer;

  const [state, setState] = useState({currentValue:new Date()});

  const {currentValue} = state;

  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  useEffect(() => {
      locale("pt");
      loadMessages(ptMessages);
    return () => {}
  }, []);

  useEffect(() => {
    if (db) {
      function vai() {
        console.log("||| começa o sync");
      }

      vai();
    }
  }, [db]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <div id="calendar-demo">
        <div style={{marginBottom:20, width:'100%'}} className="widget-container">
          <Calendar
            id="calendar-container"
            value={currentValue}
            onValueChanged={onCurrentValueChanged}
            //onOptionChanged={onZoomLevelChanged}
            min={new Date((new Date).getTime() - 1000 * 60 * 60  )}
            max={new Date((new Date).getTime() + 1000 * 60 * 60 * 24 * 10)}
            disabledDates={(data) => data.view === 'month' && isWeekend(data.date)}
            firstDayOfWeek={0}
            zoomLevel={'month'}
            cellRender={CustomCell} 
            width='100%'/>
        </div>
        <div style={{marginBottom:20, width:'100%'}} className="dx-field">
        <div className="dx-field-label">Horário: </div>
            <div className="dx-field-value">
              <SelectBox items={horarios}
                placeholder="Escolha aqui"
                showClearButton={true} />
            </div>
          </div>
      </div>;
      case 1:
        return <div style={{marginBottom:20, width:'100%'}}>
                <TagBox
                  placeholder="Selecione sua mesa aqui..."
                  dataSource={lugares}
                  valueExpr="ID"
                  defaultValue={[]}
                  grouped={true}
                  displayExpr="Name"
                  />
              </div>
      case 2:
        return <Card style={{marginBottom:20, width:'100%'}} className={classes.root}>
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Reserva de mesa para
                  </Typography>
                  <Typography variant="h5" component="h2">
                    Phillipe Murray
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    Dia 19 de Fevereiro as 11:00.
                  </Typography>
                  <br/>
                  <Chip
                    label="Mesa com 4 lugares"
                  />
                  <Chip
                    label="Mesa com 2 lugares"
                  />
                  <Typography style={{marginTop:10}} variant="body2" component="p">
                    Se atente ao horário escolhido. Chegar 30 minutos antes.
                  </Typography>
                </CardContent>
              </Card>;
      default:
        return <Typography>Unknown step</Typography>;
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const CustomCell = (cell) => {
    return (
      <span className={getCellCssClass(cell.date)}>
        { cell.text }
      </span>
    );
  }

  const getCellCssClass = (date) => {
    let cssClass = '';
    const holydays = [[1, 0], [4, 6], [25, 11]];
  
    if(isWeekend(date))
    { cssClass = 'weekend'; }
  
    holydays.forEach((item) => {
      if(date.getDate() === item[0] && date.getMonth() === item[1]) {
        cssClass = 'holyday';
        return false;
      }
    });
  
    return cssClass;
  }
  

  function getTodayDate() {
    const parsedDate1 = parseISO(new Date().toISOString());
    return parsedDate1;
  }

  return (
    <React.Fragment>
      <div>
        <div style={{ textAlign: "center", paddingTop:20, fontSize:20, fontWeight:'bold' }}>Reserva de mesas</div>
        <ThemeProvider theme={theme}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label.label}</StepLabel>
            <StepContent  >
              {getStepContent(index)}
              <div className={classes.actionsContainer}>
                <div>
                 {activeStep!=0 && <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Voltar
                  </Button>
                  }
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1 ? 'Reservar' : 'Avançar'}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      </ThemeProvider>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} >
            Reset
          </Button>
        </Paper>
      )}
    </div>
        
      
    </React.Fragment>
  );
};
