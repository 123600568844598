import React, { useReducer, Fragment, useEffect, useState } from 'react';
import { Container } from "semantic-ui-react";
import { Route, Switch, useHistory   } from "react-router-dom";



import {Cardapio} from "./pages/Cardapio";
import {Home} from "./pages/Home";
import {Takeaway} from "./pages/Takeaway";
import {Reserva} from "./pages/Reserva";
import {Cardapioadm} from "./pages/Cardapioadm";
import Header from "./pages/Header";
import {Rodape} from "./pages/Rodape";
import {Dump} from "./pages/Dump";


import { logginReducer, Dispatch, log, DB } from './hooks/hooks';

//const Header = lazy(() => import('./pages/Header'));
//const Cardapio = lazy(() => import('./pages/Cardapio'));

export default function App() {

 


  const reducer = useReducer(logginReducer, {token:false, pedido:[], db:null, items:null, language:'pt'})
  const [{pedido}, dispatch] = reducer;

  const localtoken = localStorage.getItem('token');
  const history = useHistory(); 

  const [currentPathname, setcurrentPathname] = useState('/')
  const [currentSearch, setcurrentSearch] = useState(null)

  const [myDB, setMyDB] = useState(null)

  const localdados = localStorage.getItem('dados');

  useEffect(() => {
   // console.log('localtoken:', localtoken)
   // dispatch({type:'token', payload:'localtoken'})
   

   history.listen((newLocation, action) => {
    log() && console.log(newLocation, action)
   //  getNewPath(newLocation, action)
    
  });

  window.addEventListener('popstate', function (event){
    window.history.pushState("null", document.title,  window.location.href);
});

    if(localtoken !== false){
      //setTimeout(() => {
        dispatch({type:'token', payload:localtoken})
     // },5000)
     // dispatch({type:'logged', payload:true})
      //startConnection() 
    }

    if(localdados){
      //setTimeout(() => {
        console.log(localdados)
        let splited = localdados.split(',')
        dispatch({type:'userdata', payload:{nome:splited[0], celular:splited[1], loaded:true}})
     // },5000)
     // dispatch({type:'logged', payload:true})
      //startConnection() 
    }

    return () => {
      window.onpopstate = null;
    }

  },[])

  const getNewPath = (newLocation, action) => {
    if (action === "PUSH") {
      log() && console.log(newLocation.pathname, currentPathname)
      if (
        newLocation.pathname !== currentPathname ||
        newLocation.search !== currentSearch
      ) {
        // Save new location
        setcurrentPathname(newLocation.pathname);
        setcurrentSearch(newLocation.search);

        // Clone location object and push it to history
        /*history.push({
          pathname: newLocation.pathname,
          search: newLocation.search
        });*/
      }
    } else {
      log() && console.log('back')
      // Send user back if they try to navigate back
      history.go(1);
    }
  }

  /*useEffect(() => {
    history.push({
      pathname: currentPathname,
      search: currentSearch
    });

  },[currentPathname])*/

 

  useEffect(() => {
    //console.log(salas)
   /* console.log('APP')
    setTimeout(() => {
      history.push({
        pathname: '/liveevent',
      });
    },5000)*/
  },[])

  

    return (
      <div >
        <Dispatch.Provider value = {reducer}>
        <Switch>
          <Fragment>
            <Container>
                <Route path="/">
                  <Header component={Header}/>
                </Route>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/idle" />
                <Route exact path="/cardapio" component={Cardapio}>
                </Route>
                <Route exact path="/takeaway">
                  <Takeaway />
                  <Rodape />
                </Route>
                <Route exact path="/cardapioadm">
                  <Cardapioadm />
                </Route> 
                <Route exact path="/reserva">
                  <Reserva />
                </Route> 
                <Route exact path="/dump">
                  <Dump />
                </Route> 
            </Container>
          </Fragment>
        </Switch>
        </Dispatch.Provider>
        </div>
    );
  
  }

